import { CSSProperties } from "react";
import DynamicSVG from "components/ui/DynamicSVG";
import { DEFAULT_ICON_SIZE } from "legacy/widgets/shared";
import { KeyValueComponentProps, KeyValueProperty } from "../types";
import { getStringValue } from "../utils";
import { useKeyClassNames } from "./keyStyles";

export const KeyValuePropertyKey = ({
  property,
  style,
  keyProps,
  iconSize,
}: {
  property: KeyValueProperty;
  style?: CSSProperties;
  keyProps?: KeyValueComponentProps["keyProps"];
  iconSize?: number;
}) => {
  const icon = property.keyProps?.icon ? (
    <DynamicSVG
      iconName={property.keyProps.icon}
      size={iconSize ?? DEFAULT_ICON_SIZE}
    />
  ) : null;
  const iconPosition = property.keyProps?.iconPosition ?? "LEFT";

  const classNames = useKeyClassNames(keyProps);

  return (
    <div className={classNames} style={style}>
      {iconPosition === "LEFT" && icon}
      <p>{getStringValue(property.label) ?? property.key}</p>
      {iconPosition === "RIGHT" && icon}
    </div>
  );
};
