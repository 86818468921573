import React, { useRef, useState } from "react";
import { ErrorMessagePlacement } from "legacy/constants/WidgetConstants";
import { CLASS_NAMES } from "legacy/themes/classnames";
import {
  ErrorInlineMessage,
  Props as ErrorInlineMessageProps,
} from "./ErrorInlineMessage";
import ErrorTooltip from "./ErrorTooltip";
import { useErrorMessages } from "./ErrorUtils";

interface Props {
  messages?: string | string[];
  children: (
    inlineError: React.ReactNode,
    onTargetMouseOver?: () => void,
    onTargetMouseLeave?: () => void,
  ) => JSX.Element[] | JSX.Element;
  errorMessagePlacement?: ErrorMessagePlacement;
  widgetId: string;
  showError: boolean;
  customTarget?: boolean;
  inlineErrorProps?: Partial<ErrorInlineMessageProps>;
  isFocused?: boolean;
  attachTo?: React.RefObject<HTMLElement>;
}

const WidgetErrorsWrapper = (props: Props): JSX.Element => {
  const messages = useErrorMessages(props.messages);
  const errorMessagePlacement =
    props.errorMessagePlacement ?? ErrorMessagePlacement.TOOLTIP;
  const containerRef = useRef<HTMLDivElement>(null);

  const [isHovered, setIsHovered] = useState(false);

  const onTargetMouseOver = () => {
    setIsHovered(true);
  };

  const onTargetMouseLeave = () => {
    setIsHovered(false);
  };

  const showPopper =
    errorMessagePlacement === ErrorMessagePlacement.TOOLTIP &&
    props.showError &&
    (isHovered || props.isFocused);

  if (errorMessagePlacement === ErrorMessagePlacement.INLINE) {
    const inlineError = (
      <ErrorInlineMessage
        widgetId={props.widgetId}
        showError={props.showError}
        errorMessages={messages}
        {...props.inlineErrorProps}
      />
    );

    return props.children(inlineError) as JSX.Element;
  }

  const children = props.children(null, onTargetMouseOver, onTargetMouseLeave);
  const tooltip = (
    <ErrorTooltip
      isOpen={!!showPopper}
      messages={messages}
      attachTo={props.attachTo?.current ?? containerRef.current}
      wrapperClassName={CLASS_NAMES.POPOVER_WRAPPER}
      placement="bottom-start"
    />
  );

  // do not add wrapper if there is only one child
  if (React.Children.count(children) === 1) {
    return (
      React.Children.map(children, (child) =>
        React.cloneElement(child, {
          onMouseOver: props.customTarget ? undefined : onTargetMouseOver,
          onMouseLeave: props.customTarget ? undefined : onTargetMouseLeave,
          ref: containerRef,
          children: (
            <>
              {child.props.children}
              {tooltip}
            </>
          ),
        }),
      ) as JSX.Element[]
    )[0];
  }

  return (
    <div
      onMouseOver={props.customTarget ? undefined : onTargetMouseOver}
      onMouseLeave={props.customTarget ? undefined : onTargetMouseLeave}
      ref={containerRef}
    >
      {children}
      {tooltip}
    </div>
  );
};

export default WidgetErrorsWrapper;
