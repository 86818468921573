import { WidgetTypes } from "@superblocksteam/shared";
import { get } from "lodash";
import { useCallback, useMemo } from "react";
import { Dispatch } from "redux";
import {
  deleteWidgetProperty,
  updateWidgetProperties,
} from "legacy/actions/controlActions";
import { deleteCurrentPage } from "legacy/actions/pageActions";
import { deleteWidgets } from "legacy/actions/widgetActions";
import { getIsColumn, getIsTab } from "legacy/selectors/entitiesSelector";
import { getWidgetPropertiesById } from "legacy/selectors/propertyPaneSelectors";
import { selectWidgetDisplayName } from "legacy/selectors/sagaSelectors";
import { isSubProperty } from "legacy/utils/ai";
import { WidgetProps } from "legacy/widgets";
import { useAppSelector } from "store/helpers";
import { selectAiState } from "store/slices/ai/selectors";
import { getWidgetIcon } from "../../Explorer/ExplorerIcons";
import { getLayoutIcon } from "../../Explorer/LayoutIcons";
import { ItemKinds } from "../ItemKindConstants";
import type { ItemKindAccessor } from "../ItemKinds";
import type { AppState } from "store/types";

export const WidgetAccessor:
  | ItemKindAccessor<ItemKinds.WIDGET>
  | ItemKindAccessor<ItemKinds.AI_EDIT> = {
  useItemName: (itemProperties: WidgetProps) => {
    const displayName = useAppSelector(
      useCallback(
        (state: AppState) =>
          selectWidgetDisplayName(state, itemProperties.widgetId),
        [itemProperties.widgetId],
      ),
    );
    const isTab = useAppSelector(
      useCallback(
        (state: AppState) => getIsTab(state, itemProperties.widgetId),
        [itemProperties.widgetId],
      ),
    );
    const isColumn = useAppSelector(
      useCallback(
        (state: AppState) => getIsColumn(state, itemProperties.widgetId),
        [itemProperties.widgetId],
      ),
    );
    return useMemo(
      () => ({
        name: itemProperties.widgetName,
        displayName,
        editable: !isColumn,
        requiresValidation: !isTab,
      }),
      [displayName, isColumn, isTab, itemProperties.widgetName],
    );
  },
  itemType: (itemProperties) => itemProperties.type,
  useItemProperties: (itemId: string) => {
    return useAppSelector(
      useCallback(
        (state: AppState) => getWidgetPropertiesById(state, itemId),
        [itemId],
      ),
    );
  },
  updateItemProperties: (
    dispatch: Dispatch<any>,
    properties: WidgetProps,
    updates: Record<string, unknown>,
  ) => {
    dispatch(updateWidgetProperties(properties?.widgetId, updates));
  },
  deleteItemProperties: (
    dispatch: Dispatch<any>,
    properties: WidgetProps,
    propertyPaths: string[],
  ) => {
    dispatch(deleteWidgetProperty(properties?.widgetId, propertyPaths));
  },
  deleteItem: (dispatch, widgetId, _itemScope, deleteAllSelected) => {
    if (widgetId === "0") {
      dispatch(deleteCurrentPage());
    } else {
      dispatch(
        deleteWidgets({
          widgetIds: deleteAllSelected ? undefined : [widgetId],
          isShortcut: false,
        }),
      );
    }
  },
  icon: (itemProperties) => {
    if (itemProperties.type === WidgetTypes.CANVAS_WIDGET) {
      return getLayoutIcon(itemProperties.layout, 16);
    }
    return getWidgetIcon(itemProperties.type, 16);
  },
  // This is used for properties shown in the Clark AI response panel
  useAreItemPropertiesLoading: (itemId: string, propertyNames: string[]) => {
    const {
      changedKeys: changedKeysByWidgetId,
      propertiesToChange: propertiesToChangeByWidgetId,
      dataTreeChanges: dataTreeChangesByWidgetId,
    } = useAppSelector(selectAiState);

    const dataTreeChanges = dataTreeChangesByWidgetId?.[itemId] || {};

    const changedKeys = changedKeysByWidgetId?.[itemId];
    const propertiesToChange = propertiesToChangeByWidgetId?.[itemId];

    return propertyNames.map((propertyName) => {
      const isInChangeSet =
        changedKeys != null &&
        (changedKeys?.includes(propertyName) ||
          get(dataTreeChanges, propertyName) != null ||
          (changedKeys ?? [])?.some((key) => isSubProperty(propertyName, key)));
      if (isInChangeSet) return false;

      const isInExpectedSet =
        propertiesToChange != null &&
        (propertiesToChange?.includes(propertyName) ||
          (propertiesToChange ?? [])?.some((key) =>
            isSubProperty(propertyName, key),
          ));
      return isInExpectedSet;
    });
  },
};
