import { useCallback, useMemo } from "react";
import { Dispatch } from "redux";

import {
  getIsColumn,
  getIsTab,
  getWidget,
} from "legacy/selectors/entitiesSelector";
import { selectWidgetDisplayName } from "legacy/selectors/sagaSelectors";
import { useAppSelector } from "store/helpers";
import { AppState } from "store/types";
import { ItemKinds, ItemTypeNonWidget } from "../ItemKindConstants";
import type { ItemKindAccessor } from "../ItemKinds";

export const AiHistoryAccessor: ItemKindAccessor<ItemKinds.AI_EDITS_HISTORY> = {
  useItemName: (itemProperties: any) => {
    const displayName = useAppSelector(
      useCallback(
        (state: AppState) =>
          selectWidgetDisplayName(state, itemProperties.widgetId),
        [itemProperties.widgetId],
      ),
    );
    const isTab = useAppSelector(
      useCallback(
        (state: AppState) => getIsTab(state, itemProperties.widgetId),
        [itemProperties.widgetId],
      ),
    );
    const isColumn = useAppSelector(
      useCallback(
        (state: AppState) => getIsColumn(state, itemProperties.widgetId),
        [itemProperties.widgetId],
      ),
    );
    return useMemo(
      () => ({
        name: itemProperties.widgetName,
        displayName,
        editable: !isColumn,
        requiresValidation: !isTab,
      }),
      [displayName, isColumn, isTab, itemProperties.widgetName],
    );
  },
  itemType: () => ItemTypeNonWidget.AI_EDITS_HISTORY,
  useItemProperties: (itemId: string) => {
    const [historyIndex, widgetId] = itemId.split(":");
    const widgetWithoutAiEdits = useAppSelector((state) =>
      getWidget(state, widgetId),
    );
    const resultsHistory = useAppSelector((state) => state.ai.resultsHistory);
    const widgetFromHistory =
      resultsHistory[parseInt(historyIndex, 10)]?.dataTreeChanges?.[widgetId];
    return {
      ...widgetWithoutAiEdits,
      ...widgetFromHistory,
    };
  },

  updateItemProperties: (
    dispatch: Dispatch<any>,
    properties: any,
    updates: Record<string, unknown>,
  ) => {
    // non-editable
  },
  deleteItemProperties: (
    dispatch: Dispatch<any>,
    properties: any,
    propertyPaths: string[],
  ) => {
    // non-editable
  },
  deleteItem: (dispatch, widgetId, _itemScope, _deleteAllSelected) => {
    // non-editable
  },
  icon: () => null,
};
