import { Interweave, Matcher } from "interweave";
import DynamicSVG from "components/ui/DynamicSVG";

class IconOnlyStringMatcher extends Matcher {
  match(string: string) {
    // Match if the string is not an 'icon' tag, and return an empty string.
    if (!string.trim().startsWith("<Icon")) {
      return { match: string, string: "", index: 0, length: 0, valid: true };
    }

    return null;
  }
  replaceWith() {
    return "";
  }
  asTag() {
    return "span";
  }
}

// This function is used to convert the icon JSX string to an SVG element.
export function getIconSVGElement(iconJSX?: string) {
  return (
    <Interweave
      content={iconJSX}
      matchers={[new IconOnlyStringMatcher("icon")]}
      transform={(node) => {
        if (node.tagName?.toLowerCase() === "icon") {
          const type = node.attributes.getNamedItem("name")?.value ?? "unknown";
          return <DynamicSVG iconName={type} />;
        }
      }}
    />
  );
}
