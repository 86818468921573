import { CLASS_NAMES } from "legacy/themes/classnames";

export const SwitchInput = ({
  isValid,
  onClick,
  isToggledOn,
  isDisabled,
}: {
  isValid: boolean;
  onClick?: () => void;
  isToggledOn: boolean;
  isDisabled: boolean;
}) => {
  return (
    <div
      className={`${CLASS_NAMES.SWITCH} ${
        !isValid ? CLASS_NAMES.ERROR_MODIFIER : ""
      }
    ${isDisabled ? CLASS_NAMES.DISABLED_MODIFIER : ""}`}
      onClick={isDisabled ? undefined : onClick}
    >
      <input type="checkbox" checked={isToggledOn} disabled={isDisabled} />
      {/* this span will be the switch slider */}
      <span></span>
    </div>
  );
};
