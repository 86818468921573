import { Dimension, Padding } from "@superblocksteam/shared";
import { useMemo } from "react";
import { GridDefaults } from "legacy/constants/WidgetConstants";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { GeneratedTheme, TextStyleWithVariant } from "legacy/themes";
import { DROPDOWN_PADDING } from "legacy/themes/constants";
import { useAppSelector } from "store/helpers";
import { getInputIconSize } from "../shared";
import { useStyleClassNames, useTypographyStyling } from "../typographyHooks";
import { getLineHeightInPxFromTextStyle } from "../typographyUtils";
import DropdownComponent, {
  INPUT_ADDITIONAL_MIN_HEIGHT,
  LABEL_EXTRA_HEIGHT_MARGIN,
  MultiselectDropDownComponentProps,
  SingleDropDownComponentProps,
} from "./DropdownComponent";
import {
  DEFAULT_DROPDOWN_BORDER,
  DEFAULT_DROPDOWN_WIDGET_INPUT_STYLE_VARIANT,
  DEFAULT_DROPDOWN_WIDGET_LABEL_STYLE_VARIANT,
} from "./constants";

type WithLayoutManagedProps = (
  | Omit<SingleDropDownComponentProps, "caretIconColor" | "inputHeightPx">
  | Omit<MultiselectDropDownComponentProps, "caretIconColor" | "inputHeightPx">
) & {
  labelProps?: {
    textStyle: TextStyleWithVariant;
    width?: Dimension<"px" | "gridUnit">;
  };
  inputProps?: {
    textStyle: TextStyleWithVariant;
    padding?: Padding;
  };
  height?: number;
};

const WIDGET_BORDER_HEIGHT = 1;

export const DropdownComponentWithLayoutManaged = (
  props: WithLayoutManagedProps,
) => {
  const generatedTheme = useAppSelector(selectGeneratedTheme);

  const labelProps = useTypographyStyling({
    textStyle: props.labelProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_DROPDOWN_WIDGET_LABEL_STYLE_VARIANT,
    applyClassNameStylesToStyle: true,
  });

  const inputProps = useTypographyStyling({
    textStyle: props.inputProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_DROPDOWN_WIDGET_INPUT_STYLE_VARIANT,
    applyClassNameStylesToStyle: true,
  });

  const labelClass = useStyleClassNames({
    textStyleVariant: labelProps.textStyleVariant, // Has fallback in place
    isLoading: props.isLoading,
    isDisabled: props.disabled,
    type: "label",
  });

  const inputClass = useStyleClassNames({
    textStyleVariant: inputProps?.textStyleVariant, // Has fallback in place
    isLoading: props.isLoading,
    isDisabled: props.isDisabled,
    type: "input",
  });

  // inputProps.textStyleVariant already has the the default fallback in place.
  // This method still requires it.
  const inputLineHeightPx = getLineHeightInPxFromTextStyle({
    textStyleVariant: inputProps?.textStyleVariant,
    nestedProps: props.inputProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_DROPDOWN_WIDGET_INPUT_STYLE_VARIANT,
    typographies: generatedTheme.typographies,
  });

  const inputPadding = props.inputProps?.padding ?? DROPDOWN_PADDING;

  const iconSize = useMemo(
    () =>
      getInputIconSize({
        width: Dimension.px(props.width ?? 0),
        componentWidth: props.width ?? 0,
        textStyle: props.inputProps?.textStyle,
        padding: props.inputProps?.padding,
        generatedTheme,
      }),
    [
      props.width,
      props.inputProps?.textStyle,
      props.inputProps?.padding,
      generatedTheme,
    ],
  );

  const labelHeightPx = props.label
    ? getLineHeightInPxFromTextStyle({
        textStyleVariant: DEFAULT_DROPDOWN_WIDGET_LABEL_STYLE_VARIANT,
        nestedProps: undefined,
        typographies: generatedTheme.typographies,
        defaultTextStyleVariant: DEFAULT_DROPDOWN_WIDGET_LABEL_STYLE_VARIANT,
      })
    : 0;

  const inputHeightPx = useMemo(() => {
    const padding = props.inputProps?.padding ?? DROPDOWN_PADDING;
    return (
      (props.height ?? 0) -
      (props.vertical ? labelHeightPx : 0) -
      (padding.top?.value ?? 0) -
      (padding.bottom?.value ?? 0) -
      DEFAULT_DROPDOWN_BORDER * 2 -
      WIDGET_BORDER_HEIGHT * 2
    );
  }, [labelHeightPx, props.height, props.inputProps?.padding, props.vertical]);

  return (
    <DropdownComponent
      {...props}
      labelClassName={labelClass}
      labelStyleOverride={labelProps?.style}
      labelWidth={props.labelProps?.width}
      inputClassName={inputClass}
      inputStyleOverride={inputProps?.style}
      inputLineHeightPx={inputLineHeightPx}
      inputPadding={inputPadding}
      caretIconColor={generatedTheme.dropdown.caratIcon}
      iconSize={iconSize}
      overflowTags
      inputHeightPx={inputHeightPx}
    />
  );
};

export const estimateInitialDropdownWidgetHeightGU = (
  theme?: GeneratedTheme,
): number => {
  if (!theme?.typographies) {
    return 5;
  }

  const labelHeight = getLineHeightInPxFromTextStyle({
    textStyleVariant: DEFAULT_DROPDOWN_WIDGET_LABEL_STYLE_VARIANT,
    nestedProps: undefined,
    typographies: theme.typographies,
    defaultTextStyleVariant: DEFAULT_DROPDOWN_WIDGET_LABEL_STYLE_VARIANT,
  });

  const inputHeight = getLineHeightInPxFromTextStyle({
    textStyleVariant: DEFAULT_DROPDOWN_WIDGET_INPUT_STYLE_VARIANT,
    nestedProps: undefined,
    typographies: theme.typographies,
    defaultTextStyleVariant: DEFAULT_DROPDOWN_WIDGET_INPUT_STYLE_VARIANT,
  });

  const totalHeightPx =
    INPUT_ADDITIONAL_MIN_HEIGHT +
    labelHeight +
    inputHeight +
    LABEL_EXTRA_HEIGHT_MARGIN;

  return Dimension.toGridUnit(
    Dimension.px(totalHeightPx),
    GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
  ).roundUp().value;
};
