import { Padding } from "@superblocksteam/shared";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { WidgetTypesArr } from "legacy/constants/WidgetConstants";
import {
  useCanvasSnapRowsUpdateHook,
  useWidgetDragResize,
} from "legacy/hooks/dragResizeHooks";
import { getWidgets } from "legacy/selectors/entitiesSelector";
import { type WidgetProps } from "legacy/widgets";
import { getApplicationSettings } from "store/slices/application/selectors";
import { getAllowedChildTypesInWidgetAncestry } from "utils/getAllowedChildTypesInWidgetAncestry";
import { customComponentType } from "../CustomComponentTypeUtil";
import { useSectionSizingContextSelector } from "../SectionWidget/SectionSizingContextSelectors";
import {
  type DropTargetContextType,
  paddingToGU,
  type UpdateTemporaryResizeValue,
} from "../base/DropTargetUtils";

export const useStackDropProps = (props: Omit<WidgetProps, "children">) => {
  const { widgetId } = props;

  const canvasWidgets = useSelector(getWidgets);
  const appSettings = useSelector(getApplicationSettings);

  const allowedChildTypes = useMemo(() => {
    return getAllowedChildTypesInWidgetAncestry({
      widgetId,
      canvasWidgets,
      registeredComponents: appSettings?.registeredComponents,
    });
  }, [appSettings?.registeredComponents, canvasWidgets, widgetId]);

  const acceptTypes = useMemo(() => {
    return [
      ...WidgetTypesArr,
      ...Object.values(appSettings?.registeredComponents ?? {}).map(
        (component) => customComponentType(component.id),
      ),
    ];
  }, [appSettings?.registeredComponents]);

  const [resizeValue, setResizeValue] = useState<
    | {
        widgetId: string;
        size: { width: number; height: number };
        position: { x: number; y: number };
      }
    | undefined
  >();
  const updateTemporaryResizeValue = useCallback<UpdateTemporaryResizeValue>(
    (
      widgetId?: string,
      size?: { width: number; height: number },
      position?: { x: number; y: number },
    ) => {
      if (widgetId == null || size == null || position == null) {
        setResizeValue(undefined);
        return;
      }
      setResizeValue({ widgetId, size, position });
    },
    [],
  );

  // for vstacks, we don't need the drag to extend, as in a vstack everything just fits
  // we extend only when resizing a component larger than the vstack canvas can fit
  const updateCanvasSnapRows = useCanvasSnapRowsUpdateHook();
  const persistDropTargetRows = useCallback(
    (widgetBeingResizedId: string, bottomRow: number) => {
      if (bottomRow <= props.height.value) return;

      updateCanvasSnapRows(widgetId, bottomRow);
    },
    [props.height.value, updateCanvasSnapRows, widgetId],
  );

  const { setCurrentDropTargetRows } = useWidgetDragResize();
  const setColumnCanvasExtensionInfo = useSectionSizingContextSelector(
    (context) => context.setColumnCanvasExtensionRows,
  );
  const updateDropTargetRows = useCallback(
    (widgetBeingResizedId: string, bottomRow: number): number => {
      setCurrentDropTargetRows(bottomRow);
      setColumnCanvasExtensionInfo({
        rows: bottomRow + paddingToGU(Padding.y(props.padding)),
        dropTargetId: widgetId,
      });
      return bottomRow;
    },
    [
      props.padding,
      setColumnCanvasExtensionInfo,
      setCurrentDropTargetRows,
      widgetId,
    ],
  );

  const contextValue = useMemo<DropTargetContextType>(() => {
    return {
      persistDropTargetRows,
      updateDropTargetRows,
      updateTemporaryResizeValue,
      temporaryResizeValue: resizeValue,
    };
  }, [
    persistDropTargetRows,
    updateDropTargetRows,
    updateTemporaryResizeValue,
    resizeValue,
  ]);

  return {
    contextValue,
    allowedChildTypes,
    acceptTypes,
  };
};
