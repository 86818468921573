import { UrlDataState } from "legacy/reducers/entityReducers/appReducer";
import { APP_MODE } from "legacy/reducers/types";
import {
  concatenateQueryParams,
  convertObjectToQueryParams,
} from "legacy/utils/Utils";
import logger from "utils/logger";
import { isRelativeURL } from "utils/url";

const mergeUrlParams = (
  inputUrl: string,
  paramsToMerge: Record<string, string>,
) => {
  const [urlBeforeHash, hash] = inputUrl.split("#");
  const [urlBeforeSearch, search] = urlBeforeHash.split("?");
  const mergedSearch = concatenateQueryParams(
    convertObjectToQueryParams(paramsToMerge),
    search,
  );
  const url = mergedSearch
    ? `${urlBeforeSearch}?${mergedSearch}`
    : urlBeforeSearch;
  return hash ? `${url}#${hash}` : url;
};

// To create consistent URLs for relative links, we need to determine the app's root URL
const getBaseUrlForApp = (
  { pathname, host, protocol }: UrlDataState,
  appMode?: APP_MODE,
): string => {
  const segments = pathname.split("/");

  // EDIT, PREVIEW: /applications/:mode/:applicationId/*
  // PUBLISHED: /applications/:applicationId/*
  const appRootSegments =
    appMode === APP_MODE.PUBLISHED
      ? segments.slice(0, 3)
      : segments.slice(0, 4);

  return `${protocol}//${host}${appRootSegments.join("/")}/`;
};

export const getLocalizedUrl = ({
  inputUrl,
  keepExistingQueryParams,
  currentPageUrlState,
  systemQueryParams,
  appMode,
}: {
  inputUrl?: string;
  keepExistingQueryParams?: boolean;
  currentPageUrlState: UrlDataState;
  systemQueryParams?: Record<string, string>;
  appMode?: APP_MODE;
}): { url: string; urlForInternalNavigation?: string } => {
  // Adding this checks as a way to avoid a crash when inputUrl is not a string.
  // The causes of the crash are unknown atm.
  if (!inputUrl?.startsWith) {
    logger.error(
      `inputUrl is not a string. Value: ${typeof inputUrl === "object" ? JSON.stringify(inputUrl) : inputUrl}`,
    );
    if (
      typeof inputUrl === "object" ||
      typeof inputUrl === "function" ||
      !inputUrl?.toString
    ) {
      inputUrl = "";
    } else {
      inputUrl = inputUrl.toString();
    }
  }

  const isRelative =
    isRelativeURL(inputUrl ?? "") &&
    (inputUrl?.startsWith(".") || inputUrl?.startsWith("/"));

  if (!isRelative && inputUrl && !inputUrl.startsWith("http")) {
    // Handle URLs without a protocol, e.g. www.example.com, by prepending https
    try {
      // If input is missing a protocol, this with throw a TypeError
      new URL(inputUrl);
    } catch (e) {
      inputUrl = `https://${inputUrl}`;
    }
  }

  // Conditionally apply current query params to relative & absolute links,
  // and always merge system query params for relative links.
  let paramsToMerge = keepExistingQueryParams
    ? currentPageUrlState.queryParams
    : {};
  paramsToMerge = isRelative
    ? { ...paramsToMerge, ...systemQueryParams }
    : paramsToMerge;

  let url = mergeUrlParams(inputUrl ?? "", paramsToMerge);

  if (url !== "" && isRelative) {
    let pathForInternalNavigation = url;
    const base = getBaseUrlForApp(currentPageUrlState, appMode);
    // if the url does not start with applications interpret `/` as relative to the app root rather than the host
    if (url.startsWith("/") && !url.startsWith("/applications")) {
      url = `.${url}`;
    }
    url = new URL(url, base).href;

    // The internal navigation logic expects a different structure
    if (pathForInternalNavigation.startsWith(".")) {
      pathForInternalNavigation = pathForInternalNavigation.slice(1);
    }
    if (!pathForInternalNavigation.startsWith("/")) {
      pathForInternalNavigation = `/${pathForInternalNavigation}`;
    }
    // The internal navigation logic expects the iframe origin
    const urlForInternalNavigation = new URL(
      pathForInternalNavigation,
      window.location.origin,
    ).href;

    return {
      url,
      urlForInternalNavigation,
    };
  }

  return { url };
};
