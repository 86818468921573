import { Editor } from "@tinymce/tinymce-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ReactComponent as SendIcon } from "assets/icons/common/send.svg";
import { useDebounce } from "hooks/ui";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { GeneratedTheme } from "legacy/themes";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { RICH_TEXT_EDITOR_ICONS } from "../shared";
import {
  CHAT_INPUT_ROW_SIZE,
  MAX_CHAT_INPUT_HEIGHT,
  MIN_CHAT_INPUT_HEIGHT,
  UserMessageObject,
} from "./Constants";

const StyledRTEditor = styled.div<{
  $height: number;
  $isLoading: boolean;
  themeColors: GeneratedTheme["colors"];
}>`
  flex: 1;
  .tox .tox-edit-area__iframe {
    background: transparent;
  }
  .tox:not([dir="rtl"]) .tox-toolbar__group:not(:first-of-type) {
    position: relative;
    height: 30px;
    ::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 7px;
      height: 16px;
      border-right: 1px solid ${({ themeColors }) => themeColors.neutral100};
    }
  }

  textarea {
    /* Prevent flickering */
    visibility: hidden;
  }

  /* Increase specificity because they are styling with extra specificity here */
  .tox.tox .tox-editor-header {
    box-shadow: none;
    border-bottom: none;
    padding: 0px;
    background: transparent;

    .tox-toolbar-overlord {
      padding: 0px;
      background: transparent;
      .tox-toolbar__primary {
        background: transparent;
      }
    }
  }
  .tox {
    width: 100%;
    flex-grow: 1;
    .tox-tbtn {
      cursor: pointer;
      .tox-tbtn__select-label {
        cursor: inherit;
      }
    }
  }
  .tox .tox-mbtn,
  .tox .tox-tbtn {
    margin: 1px 1px 1px 0;
    .tox-icon svg path {
      fill: ${({ themeColors }) => themeColors.neutral900};
    }
    .tox-icon svg.no-fill path {
      fill: transparent;
      stroke: ${(props) => props.themeColors.neutral900};
    }
  }
  .tox .tox-menubar,
  .tox .tox-toolbar__group {
    padding: 0 5px 0 6px;
  }
  .tox-tinymce {
    border: none;
    height: ${(props) => props.$height}px !important;
    ${(props) => props.$isLoading && `display: none`}
  }
`;
interface ChatInputProps {
  onMessageSend: (
    message: UserMessageObject,
    callbackFn: (success: boolean) => void,
  ) => void;
  enableImages: boolean;
  enableEmojis: boolean;
  enableEnterToSend: boolean;
  placeholderText?: string;
  onHeightChange: (height: number) => void;
  height: number;
  onMessageChange: (message: UserMessageObject) => void;
  isDisabled: boolean;
}

const SendButton = styled.button`
  height: min-content;
  padding: 8px;
  cursor: pointer;

  svg {
    height: 20px;
    width: 20px;
  }
`;

const ChatInputWrapper = styled.div`
  display: flex;
  align-items: end;
  width: 100%;
  .tox {
    border-radius: 0px;
  }
`;

const ChatInput = (props: ChatInputProps) => {
  const {
    onMessageSend,
    enableImages,
    enableEnterToSend,
    placeholderText,
    enableEmojis,
    height,
    onHeightChange,
    onMessageChange,
    isDisabled,
  } = props;
  const generatedTheme = useSelector(selectGeneratedTheme);
  const editorRef = useRef<Editor | null>(null);
  const [textValue, setTextValue] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [isScriptLoading, setIsScriptLoading] = useState(true);
  const handleInit = useCallback(
    (event: any, editor: any) => {
      editorRef.current = editor;
      setIsScriptLoading(false);
      if (isDisabled) {
        editor.mode.set("readonly");
      }
    },
    [isDisabled],
  );

  useEffect(() => {
    if (editorRef.current) {
      (editorRef.current as any).mode.set(isDisabled ? "readonly" : "design");
    }
  }, [isDisabled]);

  const extractImages = useCallback((html: string) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    const images = doc.getElementsByTagName("img");
    const imageData: string[] = [];
    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      const src = image.getAttribute("src");
      if (src) {
        imageData.push(src);
      }
    }
    return imageData;
  }, []);

  const handleTextChange = useCallback(
    (val: string, needsHeightChange: boolean, editor: any) => {
      if (!isSending) {
        onMessageChange({
          richText: val,
          plainText: editor.getContent({ format: "text" }),
          images: extractImages(val),
        });
      }

      if (needsHeightChange) {
        if (!val) {
          onHeightChange(MIN_CHAT_INPUT_HEIGHT);
          return;
        }
        const totalLineBreaks = (val.match(/(<br>|<li>|&nbsp;)/g) || []).length;
        let estHeight;
        if (totalLineBreaks > 0) {
          estHeight =
            totalLineBreaks * CHAT_INPUT_ROW_SIZE +
            MIN_CHAT_INPUT_HEIGHT -
            CHAT_INPUT_ROW_SIZE;
        } else {
          const estLines = Math.ceil(val.length / 100);
          estHeight = Math.max(
            estLines * CHAT_INPUT_ROW_SIZE +
              MIN_CHAT_INPUT_HEIGHT -
              CHAT_INPUT_ROW_SIZE,
            MIN_CHAT_INPUT_HEIGHT,
          );
        }
        const newHeight = Math.min(MAX_CHAT_INPUT_HEIGHT, estHeight);
        if (newHeight > height) {
          onHeightChange(newHeight);
        } else if (newHeight < height - CHAT_INPUT_ROW_SIZE) {
          onHeightChange(height - CHAT_INPUT_ROW_SIZE);
        }
      }
    },
    [height, onHeightChange, onMessageChange, extractImages, isSending],
  );

  const debouncedOnChange = useDebounce(handleTextChange, 100);

  const onTextChange = useCallback(
    (val: string, editor: any) => {
      setTextValue(val);
      let needsHeightChange = true;
      // check if it has images, if so set to max height (if not already)
      if (val.includes("<img")) {
        needsHeightChange = false;
        if (height !== MAX_CHAT_INPUT_HEIGHT) {
          onHeightChange(MAX_CHAT_INPUT_HEIGHT);
        }
      }
      debouncedOnChange && debouncedOnChange(val, needsHeightChange, editor);
    },
    [onHeightChange, height, debouncedOnChange],
  );

  const sendMessage = useCallback(
    (editor?: any) => {
      if (!textValue || isSending) return;
      const ed = editor ?? editorRef.current;
      const richText = textValue;
      const plainText = ed?.getContent?.({ format: "text" }) ?? "";
      setIsSending(true);
      setTextValue("");

      const messageSendCallback = (success: boolean) => {
        setIsSending(false);
        if (!success) {
          setTextValue(richText || "");
        } else {
          onHeightChange(MIN_CHAT_INPUT_HEIGHT);
        }
      };
      onMessageSend(
        {
          richText,
          plainText,
          images: extractImages(richText),
        },
        messageSendCallback,
      );
    },
    [extractImages, onHeightChange, textValue, onMessageSend, isSending],
  );

  const onKeyDown = useCallback(
    (e: KeyboardEvent, editor: any) => {
      const isEnterKey = e.key === "Enter" || e.keyCode === 13;
      if (isEnterKey && enableEnterToSend && !e.shiftKey) {
        e.preventDefault();
        e.stopPropagation();
        sendMessage(editor);
      }
    },
    [sendMessage, enableEnterToSend],
  );
  const isDarkMode = generatedTheme.mode === "DARK";

  return (
    <ChatInputWrapper className={`${CLASS_NAMES.BORDER_TOP}`}>
      <StyledRTEditor
        $height={height}
        $isLoading={isScriptLoading}
        themeColors={generatedTheme.colors}
      >
        <Editor
          onInit={handleInit}
          // we need to reinit the edior when these change
          key={`${placeholderText}-${enableImages}-${enableEmojis}-${enableEnterToSend}-${isDarkMode}`}
          value={textValue}
          onEditorChange={onTextChange}
          onKeyDown={onKeyDown}
          scriptLoading={{
            async: true,
          }}
          tinymceScriptSrc={
            "https://cdnjs.cloudflare.com/ajax/libs/tinymce/6.2.0/tinymce.min.js"
          }
          init={{
            ...(isDarkMode
              ? {
                  skin: "oxide-dark",
                  content_css: "dark",
                }
              : {}),
            height: height - 40,
            statusbar: false,
            branding: false,
            promotion: false,
            resize: false,
            menubar: "",
            plugins: [
              "autolink",
              "lists",
              "link",
              ...(enableImages ? ["image"] : []),
              "video",
              "charmap",
              "anchor",
              "searchreplace",
              "insertdatetime",
              "media",
              "wordcount",
              "pagebreak",
              ...(enableEmojis ? ["emoticons"] : []),
            ],
            contextmenu: "",
            placeholder: placeholderText,
            toolbar: `${
              enableImages ? "image" : ""
            } link | bold italic strikethrough codeformat | bullist numlist ${
              enableEmojis ? "| emoticons" : ""
            }`,
            content_style: `body { 
                font-size: 13px; 
                margin: 0.5rem;
                background: ${generatedTheme.inputs.input.backgroundColor.default}
            } 
            body p { margin: 0 }
            body img { display: block; max-width: 100%; max-height: 120px; width: auto; height: auto; }
            .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before { color: #A4AAB7}`,
            setup: (editor) => {
              Object.entries(RICH_TEXT_EDITOR_ICONS).forEach(
                ([iconName, icon]) => {
                  editor.ui.registry.addIcon(iconName, icon);
                },
              );
              editor.ui.registry.addToggleButton("codeformat", {
                icon: "sourcecode",
                onAction: function (api) {
                  api.setActive(!api.isActive());
                  editor.execCommand("mceToggleFormat", false, "code");
                },
                onSetup: (api) => {
                  api.setActive(editor.formatter.match("code"));
                  const changed = editor.formatter.formatChanged(
                    "code",
                    (state) => api.setActive(state),
                  );
                  return () => changed.unbind();
                },
              });
            },
            image_title: true,
          }}
        />
      </StyledRTEditor>

      <SendButton
        onClick={() => sendMessage(undefined)}
        disabled={!textValue || isSending}
        data-test="chat-send-message"
        className={CLASS_NAMES.TERTIARY_BUTTON}
        style={{ paddingBottom: "12px" }}
      >
        <SendIcon />
      </SendButton>
    </ChatInputWrapper>
  );
};

export default ChatInput;
