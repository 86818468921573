import { ReactComponent as HorizontalAlignmentLeft } from "assets/icons/common/horizontal-alignment-left.svg";
import { ReactComponent as HorizontalAlignmentMiddle } from "assets/icons/common/horizontal-alignment-middle.svg";
import { ReactComponent as HorizontalAlignmentRight } from "assets/icons/common/horizontal-alignment-right.svg";
import {
  PropsPanelCategory,
  type PropertyPaneCategoryConfig,
} from "legacy/constants/PropertyControlConstants";
import { ImageAlign } from "legacy/constants/WidgetConstants";
import { PanelCategory } from "legacy/pages/Editor/PropertyPane/propertyPaneCategoryUtils";
import { DEFAULT_CONTAINER_BORDER_OBJECT } from "legacy/themes/constants";
import HelpTooltip from "legacy/widgets/Shared/HelpTooltip";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import { sizeSection, visibleProperties } from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { borderProperty } from "../styleProperties";
import { type ImageWidgetProps } from "./ImageWidget";

const config: PropertyPaneCategoryConfig<ImageWidgetProps>[] = [
  PanelCategory(PropsPanelCategory.Content, [
    {
      helpText: (
        <HelpTooltip
          text="Renders the image from the URL or Data URL in the component."
          docsUrl="https://docs.superblocks.com/applications/components-library/image#display-an-image"
        />
      ),
      propertyName: "image",
      label: "Image",
      controlType: "INPUT_TEXT",
      placeholderText: "URL or Data URL",
      isBindProperty: true,
      isTriggerProperty: false,
    },
  ]),
  PanelCategory(PropsPanelCategory.Appearance, [
    {
      propertyName: "backgroundColor",
      label: "Background",
      helpText: "Changes the color of the background",
      controlType: "COLOR_PICKER",
      themeValue: "transparent", // TODO: make removable
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
    },
    borderProperty({
      defaultValue: DEFAULT_CONTAINER_BORDER_OBJECT,
    }),
    {
      propertyName: "borderRadius",
      label: "Border radius",
      placeholderText: "15px or 50%",
      controlType: "INPUT_TEXT",
      isBindProperty: false,
      isTriggerProperty: false,
    },
    {
      propertyName: "fillContainer",
      label: "Fill the container",
      controlType: "SWITCH",
      isBindProperty: false,
      isTriggerProperty: false,
    },
    {
      propertyName: "align",
      helpText: "Sets the alignment of the image",
      label: "Align",
      controlType: "RADIO_BUTTON_GROUP",
      options: [
        {
          icon: <HorizontalAlignmentLeft />,
          value: ImageAlign.LEFT,
        },
        {
          icon: <HorizontalAlignmentMiddle />,
          value: ImageAlign.CENTER,
        },
        {
          icon: <HorizontalAlignmentRight />,
          value: ImageAlign.RIGHT,
        },
      ],
      isBindProperty: false,
      isTriggerProperty: false,
      hidden: (props) => !props.fillContainer,
    },
    {
      helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
      propertyName: "animateLoading",
      label: "Loading animation",
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
    },
  ]),
  PanelCategory(PropsPanelCategory.Layout, [
    sizeSection({
      heightSupportsFitContent: true,
      widthSupportsFitContent: true,
    }),
    ...visibleProperties({ useJsExpr: false }),
  ]),
  PanelCategory(PropsPanelCategory.EventHandlers, [
    getPopoverConfig(
      "onClick",
      "Triggers an action when a user clicks the image",
    ),
  ]),
];

export default config;
