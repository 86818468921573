import equal from "@superblocksteam/fast-deep-equal/es6";
import React, { memo, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useCallbackAsRef } from "hooks/ui";
import { setCanvasSelectionStateAction } from "legacy/actions/canvasSelectionActions";
import { selectWidgets } from "legacy/actions/widgetActions";
import { CanvasLayout, WidgetTypes } from "legacy/constants/WidgetConstants";
import { APP_MODE } from "legacy/reducers/types";
import {
  getAppMode,
  getResponsiveCanvasScaleFactor,
  getResponsiveCanvasWidth,
} from "legacy/selectors/applicationSelectors";
import {
  getCurrentPageId,
  getMainContainerWidgetId,
} from "legacy/selectors/editorSelectors";
import { getWidget } from "legacy/selectors/entitiesSelector";
import { useAppDispatch } from "store/helpers";
import { colors } from "styles/colors";
import createLoggedObserver from "utils/createLoggedObserver";
import { SelectedArenaDimensions } from "./constants";
import type { AppState } from "store/types";

interface Rectangle {
  top: number;
  left: number;
  width: number;
  height: number;
}

type RectangleWithWidgetId = Rectangle & {
  widgetId: string;
};

function isOverlapping(rect1: Rectangle, rect2: Rectangle): boolean {
  return !(
    rect2.left > rect1.left + rect1.width ||
    rect2.left + rect2.width < rect1.left ||
    rect2.top > rect1.top + rect1.height ||
    rect2.top + rect2.height < rect1.top
  );
}

function normalizeRectangle(rect: Rectangle): Rectangle {
  let { top, left, width, height } = rect;

  if (width < 0) {
    left += width;
    width = Math.abs(width);
  }

  if (height < 0) {
    top += height;
    height = Math.abs(height);
  }

  return { top, left, width, height };
}

const initRectangle = (): SelectedArenaDimensions => ({
  top: 0,
  left: 0,
  width: 0,
  height: 0,
});

const StyledSelectionCanvas = styled.canvas`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

const PARENT_WIDGETS_WAIT_FOR_RENDER: string[] = [
  WidgetTypes.MODAL_WIDGET,
  WidgetTypes.SLIDEOUT_WIDGET,
  WidgetTypes.SECTION_WIDGET,
];

const MOVE_THRESHOLD = 2; // pixel value to decide if mouse has moved

export const CanvasSelectionArena = memo(
  ({
    widgetId,
    parentId,
    snapColumnSpace,
    snapRowSpace,
    layout,
    childIds,
  }: {
    widgetId: string;
    parentId?: string;
    snapColumnSpace: number;
    snapRowSpace: number;
    layout?: CanvasLayout;
    childIds?: string[];
  }) => {
    const canvasId = `canvas-selection-${widgetId}`;
    const dispatch = useAppDispatch();

    // Selectors
    // ---------------------
    const appMode = useSelector(getAppMode);
    const responsiveCanvasScaledWidth = useSelector(getResponsiveCanvasWidth);
    const canvasScaleFactor = useSelector(getResponsiveCanvasScaleFactor);
    const parentWidget: ReturnType<typeof getWidget> | undefined = useSelector(
      (state: AppState) => getWidget(state, parentId || ""),
    );
    const mainContainerID = useSelector(getMainContainerWidgetId);
    const currentPageId = useSelector(getCurrentPageId);

    const isParentSelectable =
      widgetId === mainContainerID ||
      (parentWidget && parentWidget.type !== WidgetTypes.GRID_WIDGET);

    // Getters
    // ---------------------
    const getSelectionCanvas = useCallback(() => {
      return document.getElementById(
        `canvas-selection-${widgetId}`,
      ) as HTMLCanvasElement;
    }, [widgetId]);

    const getCanvasCtx = useCallback(() => {
      return getSelectionCanvas().getContext("2d") as CanvasRenderingContext2D;
    }, [getSelectionCanvas]);

    const getContainerParent = useCallback(() => {
      return document
        .getElementById(`drop-target-${widgetId}`)
        ?.querySelector(".container-component") as HTMLElement;
    }, [widgetId]);

    // Refs
    // ---------------------
    const boundingRectsRelativeToParent = React.useRef<RectangleWithWidgetId[]>(
      [],
    );
    const containerOffset = React.useRef<{ x: number; y: number }>({
      x: 0,
      y: 0,
    });
    const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
    const draggedAfterInit = React.useRef<boolean>(false);
    const selectionRectangleRef = React.useRef(initRectangle());
    const dirtyRectRef = React.useRef<SelectedArenaDimensions | undefined>(
      undefined,
    );
    const isDraggingRef = React.useRef<boolean>(false);

    // use initToGetUpdatedSize to tell if this init is forced to get the updated size of parent div
    // it happens in modal when we use bounding rect for height & width, the render of parent div happens
    // after the state update. The bounding react does not have updated height & width yet.
    const init = (initToGetUpdatedSize?: boolean) => {
      isDraggingRef.current = false;
      dirtyRectRef.current = undefined;
      draggedAfterInit.current = false;
      selectionRectangleRef.current = initRectangle();
      canvasRef.current = getSelectionCanvas();
      const selectionCanvas = canvasRef.current;

      const { devicePixelRatio } = window;
      const boundingRect = selectionCanvas.getBoundingClientRect();
      const height = boundingRect.height / canvasScaleFactor;
      const width = boundingRect.width / canvasScaleFactor;

      if (height && width) {
        // We need to limit the height of canvas to 4096*dpr, otherwise it will thrash memory in the browser
        const h = Math.min(height, 4096);
        const scale = 1 / (h / height);
        const w = width / scale;

        selectionCanvas.width = w * devicePixelRatio;
        selectionCanvas.height = h * devicePixelRatio;
        const canvasCtx = getCanvasCtx();
        canvasCtx.scale(devicePixelRatio / scale, devicePixelRatio / scale);
      }
      selectionCanvas.addEventListener("click", onClick, false);
      selectionCanvas.addEventListener("mousedown", onMouseDown, false);
      selectionCanvas.addEventListener("mousemove", onMouseMove, false);
      selectionCanvas.addEventListener("mouseleave", onMouseLeave, false);
      selectionCanvas.addEventListener("mouseenter", onMouseEnter, false);

      if (!initToGetUpdatedSize) {
        // If this init is triggerred on purpose, we do not need another force init
        draggedAfterInit.current = false;
      }
    };

    const getSelectionDimensions = () => {
      const selectionRectangle = selectionRectangleRef.current;

      const top =
        selectionRectangle.height < 0
          ? selectionRectangle.top - Math.abs(selectionRectangle.height)
          : selectionRectangle.top;
      const left =
        selectionRectangle.width < 0
          ? selectionRectangle.left - Math.abs(selectionRectangle.width)
          : selectionRectangle.left;
      const width = Math.abs(selectionRectangle.width);
      const height = Math.abs(selectionRectangle.height);

      // TODO (layouts): drag to select is somewhat broken with zoom
      return {
        top: top * canvasScaleFactor,
        left: left * canvasScaleFactor,
        width,
        height,
      };
    };

    const drawRectangle = (selectionDimensions: SelectedArenaDimensions) => {
      const strokeWidth = 1;
      const canvasCtx = getCanvasCtx();

      const dirtyRect = dirtyRectRef.current;
      if (dirtyRect) {
        canvasCtx.clearRect(
          dirtyRect.left,
          dirtyRect.top,
          dirtyRect.width,
          dirtyRect.height,
        );
      }
      canvasCtx.beginPath();
      canvasCtx.lineWidth = strokeWidth;
      canvasCtx.strokeStyle = colors.ACCENT_BLUE_500;
      canvasCtx.strokeRect(
        selectionDimensions.left - strokeWidth,
        selectionDimensions.top - strokeWidth,
        selectionDimensions.width + 2 * strokeWidth,
        selectionDimensions.height + 2 * strokeWidth,
      );
      canvasCtx.fillStyle = `${colors.ACCENT_BLUE_600}0F`;
      canvasCtx.fillRect(
        selectionDimensions.left,
        selectionDimensions.top,
        selectionDimensions.width,
        selectionDimensions.height,
      );
      canvasCtx.closePath();

      // Overflow is needed because the scale is not always 1
      const overflow = 8;
      dirtyRectRef.current = {
        left: selectionDimensions.left - strokeWidth - overflow,
        top: selectionDimensions.top - strokeWidth - overflow,
        width: selectionDimensions.width + 2 * strokeWidth + 2 * overflow,
        height: selectionDimensions.height + 2 * strokeWidth + 2 * overflow,
      };
    };

    const onMouseLeave = () => {
      if (isDraggingRef.current) {
        // if we drag and move the cursor outside the canvas (e.g. to api pane)
        // we will need to register the onClick on document in case we release the cursor outside
        document.body.addEventListener("mouseup", onClick, false);
      }
    };

    const onMouseEnter = () => {
      document.body.removeEventListener("mouseup", onClick);
    };

    // onClick and onMouseUp will both be called at the end of drag & select
    // we have to add stopPropagation in onClick, to avoid unexpected behavior doing unselect by clicking on empty area on canvas
    // onClick will be called after onMouseUp, if we dispatch action in onMouseUp, the useEffect will be called again and cause issues in onClick, so we just use onClick to handle end of drag & select
    const onClick = useCallbackAsRef((e: MouseEvent) => {
      const selectionRectangle = selectionRectangleRef.current;
      const canvasCtx = getCanvasCtx();
      const dirtyRect = dirtyRectRef.current;
      const selectionCanvas = getSelectionCanvas();

      isDraggingRef.current = false;
      draggedAfterInit.current = false; // this potentially gets set to true during onMouseDown, so we should reset

      const hasMoved =
        Math.abs(selectionRectangle.height) +
          Math.abs(selectionRectangle.width) >
        MOVE_THRESHOLD;

      if (dirtyRect) {
        canvasCtx.clearRect(
          dirtyRect.left,
          dirtyRect.top,
          dirtyRect.width,
          dirtyRect.height,
        );
      }
      selectionCanvas.style.zIndex = "";

      if (hasMoved) {
        selectionRectangleRef.current = initRectangle();
        dispatch(setCanvasSelectionStateAction(false, widgetId));
        e.stopPropagation();
      } else {
        //if not moved, user is clicking on canvas to deselect all
        dispatch(setCanvasSelectionStateAction(false, widgetId, true));
      }
    });

    const onMouseDown = useCallbackAsRef((e: any) => {
      const isRegularClick = e.button === 0 && !e.ctrlKey;
      if (!isRegularClick) {
        return;
      }
      if (
        !draggedAfterInit.current &&
        PARENT_WIDGETS_WAIT_FOR_RENDER.includes(parentWidget?.type)
      ) {
        // only for some components (modal, section, etc) as it is using bounding rect's width and height which is one render behind
        init(true);
        draggedAfterInit.current = true;
      }
      if (!isParentSelectable) return;

      const selectionCanvas = getSelectionCanvas();

      selectionRectangleRef.current.left =
        e.offsetX - selectionCanvas.offsetLeft;
      selectionRectangleRef.current.top = e.offsetY - selectionCanvas.offsetTop;
      selectionRectangleRef.current.width = 0;
      selectionRectangleRef.current.height = 0;
      isDraggingRef.current = true;
      const containerParent = getContainerParent();
      const containerInner = containerParent.firstChild as HTMLElement;

      // get the bounding Rects of each widget
      // get the closest parent element with the class "canvas"
      const containerComponent = containerParent.firstChild as HTMLElement;
      const widgetElements = (childIds ?? []).reduce(
        (
          acc: Array<{
            element: HTMLElement;
            widgetId: string;
          }>,
          childId,
        ) => {
          const element = document.getElementById(`widget-${childId}`);
          if (element) {
            acc.push({ element, widgetId: childId });
          }
          return acc;
        },
        [],
      );

      const parentRect = containerComponent.getBoundingClientRect();

      boundingRectsRelativeToParent.current = widgetElements.map(
        ({ element, widgetId }) => {
          const rect = element.getBoundingClientRect();
          return {
            widgetId,
            top: rect.top - parentRect.top,
            left: rect.left - parentRect.left,
            width: rect.width,
            height: rect.height,
          };
        },
      );

      // Now get the offset to account for the padding
      // Get the bounding rectangles for both elements
      const selectionCanvasRect = selectionCanvas?.getBoundingClientRect();
      const containerInnerRect = containerInner.getBoundingClientRect();

      containerOffset.current = {
        x: (selectionCanvasRect?.left || 0) - (containerInnerRect?.left || 0),
        y: (selectionCanvasRect?.top || 0) - (containerInnerRect?.top || 0),
      };

      dispatch(setCanvasSelectionStateAction(true, widgetId));
      // bring the canvas to the top layer
      selectionCanvas.style.zIndex = "2";
    });

    const lastSelectedWidgetIds = React.useRef<string[]>([]);
    const selectWidgetsInternal = useCallback(
      (widgetIds: string[]) => {
        if (!equal(lastSelectedWidgetIds.current, widgetIds)) {
          dispatch(selectWidgets(widgetIds));
          lastSelectedWidgetIds.current = widgetIds;
        }
      },
      [dispatch],
    );

    const onMouseMove = useCallbackAsRef((e: any) => {
      if (isDraggingRef.current) {
        dispatch(setCanvasSelectionStateAction(true, widgetId));

        const selectionCanvas = getSelectionCanvas();
        selectionRectangleRef.current.width =
          e.offsetX -
          selectionCanvas.offsetLeft -
          selectionRectangleRef.current.left;
        selectionRectangleRef.current.height =
          e.offsetY -
          selectionCanvas.offsetTop -
          selectionRectangleRef.current.top;
        const selectionDimensions = getSelectionDimensions();
        drawRectangle(selectionDimensions);

        // Adjust the selectionRectangle
        const adjustedSelectionRectangle = {
          ...selectionRectangleRef.current,
          left: selectionRectangleRef.current.left + containerOffset.current.x,
          top: selectionRectangleRef.current.top + containerOffset.current.y,
        };
        const normalizedSelectionRectangle = normalizeRectangle(
          adjustedSelectionRectangle,
        );
        const overlappingRects = boundingRectsRelativeToParent.current.filter(
          (rect) => isOverlapping(rect, normalizedSelectionRectangle),
        );
        const overlappingWidgetIds = overlappingRects.map(
          (rect) => rect.widgetId,
        );

        selectWidgetsInternal(overlappingWidgetIds);
      }
    });

    useEffect(() => {
      if (appMode !== APP_MODE.EDIT) return;

      const canvas = getSelectionCanvas();
      const canvasCtx = getCanvasCtx();
      if (!canvas || !canvasCtx) return;

      // Watch for width changes and re-initialize if needed
      const canvasRootEl = document.getElementById("canvas-root");
      if (!canvasRootEl) {
        throw new Error("Canvas root element not found");
      }
      const resizeObserver = createLoggedObserver(
        "CanvasSelectionArena",
        () => {
          init();
        },
      );

      // Start observing the element
      resizeObserver.observe(canvasRootEl);

      init();

      return () => {
        canvas.removeEventListener("mousedown", onMouseDown);
        canvas.removeEventListener("mousemove", onMouseMove);
        canvas.removeEventListener("mouseleave", onMouseLeave);
        canvas.removeEventListener("mouseenter", onMouseEnter);
        canvas.removeEventListener("click", onClick);
        document.body.removeEventListener("mouseup", onClick);
        resizeObserver.unobserve(canvasRootEl);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      canvasId,
      currentPageId,
      appMode,
      widgetId,
      dispatch,
      isParentSelectable,
      responsiveCanvasScaledWidth,
      snapColumnSpace,
      snapRowSpace,
      canvasScaleFactor,
      parentWidget?.type,
      parentWidget?.widthPreset,
      parentWidget?.heightPreset,
      parentWidget?.height,
      mainContainerID,
      layout,
    ]);

    // We need to maintain the MainContainer test id for cypress tests
    // until we update to support multiple canvases on the same main page
    // with the layouts project
    const canvasSelectiontestId =
      widgetId === mainContainerID ? "MainContainer" : widgetId;
    return appMode === APP_MODE.EDIT ? (
      <StyledSelectionCanvas
        id={canvasId}
        data-test={`canvas-selection-${canvasSelectiontestId}`}
      />
    ) : null;
  },
);

CanvasSelectionArena.displayName = "CanvasSelectionArena";
