import {
  ApplicationScope,
  Dimension,
  getNextEntityName,
  WidgetTypes,
} from "@superblocksteam/shared";
import { useCallback, useContext } from "react";
import { useStore } from "react-redux";
import { EditorContext } from "legacy/components/editorComponents/EditorContextProvider";
import { PAGE_WIDGET_ID } from "legacy/constants/WidgetConstants";
import { useWidgetSelection } from "legacy/hooks/dragResizeHooks";
import { getWidgetBlueprint } from "legacy/mockResponses/selectors";
import {
  calculateNewWidgetPosition,
  calculateNewWidgetPositionOnMousePosition,
} from "legacy/sagas/WidgetOperationsSagasUtils";
import { getResponsiveCanvasScaleFactor } from "legacy/selectors/applicationSelectors";
import { getDataTree } from "legacy/selectors/dataTreeSelectors";
import {
  getFlattenedCanvasWidget,
  getOccupiedSpacesSelectorForContainer,
} from "legacy/selectors/editorSelectors";
import { getWidgets } from "legacy/selectors/entitiesSelector";
import {
  getExistingWidgetNames,
  getWidgetsMeta,
} from "legacy/selectors/sagaSelectors";
import { getOpenModalOrSlideout } from "legacy/selectors/widgetSelectors";
import { getPasteParentDetails } from "legacy/utils/PasteSagaUtils";
import { generateReactKey } from "legacy/utils/generators";
import { useCreateOpenButton } from "legacy/utils/useCreateOpenButton";
import { AppState } from "store/types";
import { getDropTarget } from "utils/drop";

// This is a cross between new widget drag and paste widget
export const useInsertWidgetByType = () => {
  const store = useStore<AppState>();
  const { selectWidgets, focusWidget } = useWidgetSelection();
  const createOpenButton = useCreateOpenButton();
  const { updateWidget } = useContext(EditorContext);

  const insertWidget = useCallback(
    async (params: {
      targetWidgetId: string | undefined | null;
      widgetType: WidgetTypes;
      insertionIndex?: number;
      mousePosition?: { x: number; y: number };
    }) => {
      const { targetWidgetId, widgetType, insertionIndex, mousePosition } =
        params;
      const newWidgetId = generateReactKey();

      const state = store.getState();
      const blueprint = getWidgetBlueprint(state, widgetType);
      if (!blueprint) {
        console.error(`No blueprint found for widget type: ${widgetType}`);
        return;
      }

      const widgets = getWidgets(state);
      const widgetMeta = getWidgetsMeta(state);

      const openModalOrSlideout = getOpenModalOrSlideout(state);
      const dataTree = getDataTree(state);
      const insertionParent = getPasteParentDetails({
        pasteTargetId: targetWidgetId ?? PAGE_WIDGET_ID,
        openModalOrSlideout,
        widgets,
        widgetMeta,
        copiedWidgetsType: widgetType,
        pageDataTree: dataTree[ApplicationScope.PAGE],
      });

      if (!insertionParent) {
        console.error("No insertion parent found");
        return;
      }

      const flattenedParent = getFlattenedCanvasWidget(
        state,
        insertionParent.widgetId,
      );

      let posInfo;
      if (insertionParent.type === WidgetTypes.CANVAS_WIDGET) {
        const widget: any = {
          ...blueprint,
          widgetId: newWidgetId,
          left: Dimension.gridUnit(0),
          top: Dimension.gridUnit(0),
        };
        const canvasScaleFactor = getResponsiveCanvasScaleFactor(state);
        const occupiedSpaces = getOccupiedSpacesSelectorForContainer(
          insertionParent.widgetId,
        )(state);

        if (mousePosition) {
          const dropTarget = await getDropTarget({
            parentId: insertionParent.widgetId,
          });

          posInfo = calculateNewWidgetPositionOnMousePosition({
            widget,
            dropTarget,
            canvasWidgets: widgets,
            canvasScaleFactor,
            mousePosition: mousePosition,
            parent: flattenedParent,
            occupiedSpaces,
          });
        } else {
          posInfo = calculateNewWidgetPosition({
            widget,
            parentId: insertionParent.widgetId,
            canvasWidgets: widgets,
          });
        }
      }

      const size = {
        width: posInfo?.width ?? blueprint.width,
        height: posInfo?.height ?? blueprint.height,
      };
      const position = {
        left: posInfo?.left ?? blueprint.left ?? Dimension.gridUnit(0),
        top: posInfo?.top ?? blueprint.top ?? Dimension.gridUnit(0),
      };

      const widgetNames = getExistingWidgetNames(state);
      let modalOrSlideoutName: string | undefined;

      if (
        widgetType === WidgetTypes.MODAL_WIDGET ||
        widgetType === WidgetTypes.SLIDEOUT_WIDGET
      ) {
        // we need to know the exact name of the modal or slideout so we can create the open button
        modalOrSlideoutName = getNextEntityName(
          widgetType === WidgetTypes.MODAL_WIDGET ? "Modal" : "Slideout",
          widgetNames,
        );
      }
      updateWidget?.("WIDGET_CREATE", insertionParent.widgetId, {
        widgetName: modalOrSlideoutName,
        position,
        type: widgetType,
        size,
        newChildIndex: insertionIndex,
        newWidgetId,
      });

      if (modalOrSlideoutName) {
        const buttonParent = getPasteParentDetails({
          pasteTargetId: targetWidgetId ?? PAGE_WIDGET_ID,
          openModalOrSlideout,
          widgets,
          widgetMeta,
          copiedWidgetsType: WidgetTypes.BUTTON_WIDGET,
          pageDataTree: dataTree[ApplicationScope.PAGE],
        });
        if (!buttonParent) {
          console.error("No suitable parent found");
          return;
        }
        const buttonBlueprint = getWidgetBlueprint(
          state,
          WidgetTypes.BUTTON_WIDGET,
        );
        const buttonPosInfo = calculateNewWidgetPosition({
          widget: {
            ...buttonBlueprint,
            left: Dimension.gridUnit(0),
            top: Dimension.gridUnit(0),
          },
          parentId: buttonParent.widgetId,
          canvasWidgets: widgets,
        });
        createOpenButton({
          position: {
            left: buttonPosInfo.left,
            top: buttonPosInfo.top,
          },
          size: {
            width: buttonPosInfo.width,
            height: buttonPosInfo.height,
          },
          parentWidgetId: buttonParent.widgetId,
          widgetToOpenInfo: {
            name: modalOrSlideoutName,
            type: widgetType,
          },
          newChildIndex: insertionIndex,
        });
      }

      requestIdleCallback(
        () => {
          selectWidgets?.([newWidgetId]);
          focusWidget(newWidgetId);
        },
        {
          timeout: 50,
        },
      );
    },
    [store, selectWidgets, createOpenButton, updateWidget, focusWidget],
  );

  return insertWidget;
};
