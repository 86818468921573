import { Dimension, Padding, getNextEntityName } from "@superblocksteam/shared";
import React, {
  useState,
  useContext,
  ReactNode,
  useEffect,
  memo,
  useCallback,
  useMemo,
  useRef,
} from "react";
import "utils/polyfills/requestIdleCallback";
import { useDrop, XYCoord, DropTargetMonitor } from "react-dnd";
import { useStore } from "react-redux";
import styled from "styled-components";
import { useWidgetAllowedDropTypeFn } from "hooks/ui/useWidgetAllowedDropTypeFn";
import { WidgetAddChild, WidgetMove } from "legacy/actions/pageActions";
import { EditorContext } from "legacy/components/editorComponents/EditorContextProvider";
import {
  GridDefaults,
  WidgetTypes,
  WIDGET_PADDING,
  WidgetTypesArr,
} from "legacy/constants/WidgetConstants";
import {
  useWidgetSelection,
  useCanvasSnapRowsUpdateHook,
  useWidgetDragResize,
  runAfterWidgetOperationsAreDone,
} from "legacy/hooks/dragResizeHooks";
import { getParentToOpenIfAny } from "legacy/hooks/useClickOpenPropPane";
import { scrollWidgetIntoView } from "legacy/pages/Editor/visibilityUtil";
import {
  selectIsDragging,
  selectIsResizing,
  selectResizingItemAutoHeight,
} from "legacy/selectors/dndSelectors";
import { shouldScrollIntoEmptySpace } from "legacy/selectors/dropTargetSelectors";
import {
  getEditorReadOnly,
  getFlattenedCanvasWidget,
  getOccupiedSpacesSelectorForContainer,
} from "legacy/selectors/editorSelectors";
import { getWidgets } from "legacy/selectors/entitiesSelector";
import {
  getSelectedWidgets,
  getSingleSelectedWidget,
  getParentIsModalOrSlideout,
  getExistingWidgetNames,
} from "legacy/selectors/sagaSelectors";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { getCanvasSnapRows as getCanvasGridRows } from "legacy/utils/WidgetPropsUtils";
import { generateDropTargetElementId } from "legacy/utils/generators";
import { useCreateOpenButton } from "legacy/utils/useCreateOpenButton";
import { useSectionSizingContextSelector } from "legacy/widgets/SectionWidget/SectionSizingContextSelectors";
import { useAppSelector } from "store/helpers";
import { getApplicationSettings } from "store/slices/application/selectors";
import { type AppState } from "store/types";
import { getAllowedChildTypesInWidgetAncestry } from "utils/getAllowedChildTypesInWidgetAncestry";
import {
  hasRuntimePositionProperties,
  scaledXYCoord,
} from "../../../utils/size";
import { getResponsiveCanvasScaleFactor } from "../../selectors/applicationSelectors";
import { WidgetPropsRuntime } from "../BaseWidget";
import { customComponentType } from "../CustomComponentTypeUtil";
import { useGetSelectedStackDragPositions } from "../StackLayout/hooks";
import { WidgetOperations } from "../WidgetOperations";
import DragLayerComponent from "./DragLayerComponent";
import {
  calculateDropTargetRows,
  DropTargetContext,
  paddingToGU,
} from "./DropTargetUtils";
import {
  getDropZoneOffsets,
  findFreePosition,
  Rect,
  isPositionFree,
} from "./ResizableUtils";
import { getNewWidgetDragOffset } from "./WidgetDragPreview";
import { getBorderThickness } from "./sizing";
import { useEdgeScrollEffect } from "./useEdgeScrollEffect";
import type { StaticWidgetProps } from "../shared";
import type { WidgetConfigProps } from "legacy/mockResponses/WidgetConfigResponse";
import type { WidgetProps } from "legacy/widgets";

const heightToPixels = (
  height: Dimension<"px" | "gridUnit" | "fillParent" | "fitContent">,
  h2?: Dimension<"px" | "gridUnit">,
  h3?: Dimension<"px" | "gridUnit">,
  h4?: Dimension<"px" | "gridUnit">,
): number => {
  let sumPx = Dimension.toPx(
    height,
    GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
  ).value;
  if (h2) {
    sumPx +=
      Dimension.toPx(h2, GridDefaults.DEFAULT_GRID_ROW_HEIGHT)?.value ?? 0;
  }
  if (h3) {
    sumPx +=
      Dimension.toPx(h3, GridDefaults.DEFAULT_GRID_ROW_HEIGHT)?.value ?? 0;
  }
  if (h4) {
    sumPx +=
      Dimension.toPx(h4, GridDefaults.DEFAULT_GRID_ROW_HEIGHT)?.value ?? 0;
  }

  return sumPx;
};

// Calculate min height, which must take into account sections
export const getMinHeightPx = (
  props: DropTargetComponentProps,
  parentWidget: StaticWidgetProps<unknown>,
): Dimension<"px" | "gridUnit"> => {
  return props.minHeight;
};

type WidgetOperationParams = {
  widgetId: WidgetProps["widgetId"];
  selectedWidgetIds?: WidgetProps["widgetId"][];
} & (
  | {
      operation: typeof WidgetOperations.WIDGET_CREATE;
      payload: Omit<WidgetAddChild, "widgetId">;
    }
  | {
      operation: typeof WidgetOperations.WIDGETS_MOVE;
      payload: Omit<WidgetMove, "widgetId">;
    }
);

const widgetOperationParams = (
  widget: WidgetProps & Partial<WidgetConfigProps>,
  widgetOffset: XYCoord,
  parentOffset: XYCoord,
  parentColumnSpace: number,
  parentRowSpace: number,
  parentWidgetId: string,
  parentColumnCount: number,
  newSize?: Rect,
): WidgetOperationParams => {
  const [left, top] = getDropZoneOffsets(
    parentColumnSpace,
    parentRowSpace,
    widgetOffset,
    parentOffset,
  );
  // If this is an existing widget, we'll have the widgetId
  // Therefore, this is a move operation on drop of the widget
  // We also need to include all the selected widgets which should
  // all be moved as part of a multi-select and move interaction
  if (widget.widgetName) {
    let rows = widget.height;
    let columns = widget.width;
    if (newSize) {
      rows = {
        value: newSize?.bottom - newSize?.top,
        mode: widget.height.mode === "px" ? "gridUnit" : widget.height.mode,
      };
      columns = {
        value: newSize?.right - newSize?.left,
        mode: widget.width.mode === "px" ? "gridUnit" : widget.width.mode,
      };
    }
    return {
      operation: WidgetOperations.WIDGETS_MOVE,
      widgetId: widget.widgetId,
      payload: {
        position: {
          left: Dimension.gridUnit(newSize?.left ?? left),
          top: Dimension.gridUnit(newSize?.top ?? top),
        },
        size: {
          height: rows,
          width: columns,
        },
        parentId: widget.parentId,
        newParentId: parentWidgetId,
      } satisfies Omit<WidgetMove, "widgetId">,
    };
    // If this is not an existing widget, we'll not have the widgetId
    // Therefore, this is an operation to add child to this container
  }
  const size = {
    // If the widget is smaller than the width, the drag system has already allowed this
    width: Dimension.min(
      widget.width,
      Dimension.gridUnit(parentColumnCount),
      parentColumnSpace,
    ).asFirst(),
    height: widget.height,
  };
  if (newSize) {
    size.width = {
      value: newSize?.right - newSize?.left,
      mode: widget.width.mode,
    };
    size.height = {
      value: newSize?.bottom - newSize?.top,
      mode: widget.height.mode,
    };
  }

  return {
    operation: WidgetOperations.WIDGET_CREATE,
    widgetId: parentWidgetId,
    payload: {
      type: widget.type,
      size,
      position: {
        top: Dimension.gridUnit(newSize?.top ?? top),
        left: Dimension.gridUnit(newSize?.left ?? left),
      },
      newWidgetId: widget.widgetId,
    } satisfies Omit<WidgetAddChild, "widgetId">,
  };
};

export type DropTargetComponentProps = Omit<WidgetPropsRuntime, "children"> & {
  children?: ReactNode;
  gridColumnSpace: number; // Width of a column in px
  gridRowSpace: number; // Height of a row in px
  gridColumns: number;
  minHeight: Dimension<"px" | "gridUnit">;
};

interface StyledDropTargetProps {
  columnWidth: number;
  gridColumns: number;
  padding?: {
    left?: Dimension<"px">;
    right?: Dimension<"px">;
  };
}

const StyledDropTarget = styled.div<StyledDropTargetProps>`
  max-width: ${(props) =>
    props.columnWidth * props.gridColumns +
    (props.padding?.left?.value ?? 0) +
    (props.padding?.right?.value ?? 0) +
    WIDGET_PADDING * 2}px;
  max-width: 100%;
  position: relative;
  user-select: none;
  box-sizing: content-box;
`;

const DropTargetComponent = (props: DropTargetComponentProps) => {
  const canDropTargetExtend = props.canExtend;

  if (!hasRuntimePositionProperties(props)) throw Error("");

  const { updateWidget } = useContext(EditorContext);

  const singleSelectedWidget = useAppSelector(getSingleSelectedWidget);
  const selectedWidgets = useAppSelector(getSelectedWidgets);

  const isResizing = useAppSelector(selectIsResizing);

  const isResizingItemAutoHeight = useAppSelector(selectResizingItemAutoHeight);

  const isDragging = useAppSelector(selectIsDragging);
  const isOpRunning = useAppSelector((state: AppState) =>
    Boolean(state.legacy.ui.editor.widgetOperations.runningOperation),
  );
  const isNudgeDisabled = useAppSelector(
    (state: AppState) => state.legacy.ui.widgetDragResize.isNudgeDisabled,
  );
  const parentIsModalOrSlideout = useAppSelector((state) =>
    getParentIsModalOrSlideout(state, props.widgetId),
  );
  const parentWidget = useAppSelector((state: AppState) =>
    getFlattenedCanvasWidget(state, props.parentId),
  );
  const scrollIntoEmptySpace = useAppSelector((state: AppState) =>
    shouldScrollIntoEmptySpace(state, props.widgetId),
  );

  const canvasScaleFactor = useAppSelector(getResponsiveCanvasScaleFactor);

  const selectOccupiedSpaces = useMemo(
    () => getOccupiedSpacesSelectorForContainer(props.widgetId),
    [props.widgetId],
  );
  const occupiedSpaces = useAppSelector(selectOccupiedSpaces);

  const theme = useAppSelector(selectGeneratedTheme);

  const minHeight = getMinHeightPx(props, parentWidget);

  const dropTargetOffsetRef = useRef({ x: 0, y: 0 });
  const [rows, setRowsInternal] = useState(
    getCanvasGridRows(props.height, minHeight),
  );

  // FIXME: We use this ref because of an infinite update loop where rows keeps getting set back and
  // forth between two values. We haven't been able to identify the root cause, but this comparison fixes
  // the bug for now. We want to remove this.
  const previousRows = useRef<number | undefined>(rows);
  useEffect(() => {
    previousRows.current = rows;
  });

  const setRows = useCallback(
    (newRows: number) => {
      if (newRows !== previousRows.current) {
        setRowsInternal(newRows);
        setTimeout(() => {
          previousRows.current = undefined;
        }, 0);
      }
    },
    [setRowsInternal],
  );

  const { selectWidgets, focusWidget } = useWidgetSelection();
  const updateCanvasSnapRows = useCanvasSnapRowsUpdateHook();

  const [columnCanvasExtensionRows, setColumnCanvasExtensionRows] =
    useSectionSizingContextSelector((context) => [
      context.columnCanvasExtensionRowsInfo,
      context.setColumnCanvasExtensionRows,
    ]);

  // Calculate the minimum rows based on the height and minHeight
  const minRows_ = useMemo(
    () =>
      Dimension.max(
        Dimension.toGridUnit(
          props.height,
          GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
        ).roundUp(),
        Dimension.toGridUnit(
          props.minHeight,
          GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
        ).roundUp(),
      ).value,
    [props.height, props.minHeight],
  );
  const maxRows = props.canExtend ? Number.MAX_SAFE_INTEGER : rows;

  // We only want the minRows to change when the user is not dragging
  // because while dragging, the user is able to alter the height of the canvas
  // by dragging the widget to the bottom of the canvas
  const [minRows, setMinRows] = React.useState<number>(minRows_);
  useEffect(() => {
    const isResizingHeight = isResizing && !isResizingItemAutoHeight;
    if (!isDragging && !isResizingHeight && !isOpRunning) {
      setMinRows(minRows_);
    }
  }, [isDragging, minRows_, isResizing, isOpRunning, isResizingItemAutoHeight]);

  const persistDropTargetRows = useCallback(
    (
      widgetId: string,
      widgetBottomRow: number,
      extraRows?: undefined | number,
    ) => {
      const rowsForDragging = calculateDropTargetRows(
        widgetId,
        widgetBottomRow,
        minRows,
        occupiedSpaces,
        canDropTargetExtend,
        extraRows,
      );

      setRows(Math.max(minRows, rowsForDragging));
      if (canDropTargetExtend) {
        const rowsToPersist = calculateDropTargetRows(
          widgetId,
          widgetBottomRow,
          minRows,
          occupiedSpaces,
          canDropTargetExtend,
          0,
        );
        updateCanvasSnapRows(props.widgetId, Math.max(minRows, rowsToPersist));
      }
    },
    [
      props.widgetId,
      minRows,
      occupiedSpaces,
      canDropTargetExtend,
      setRows,
      updateCanvasSnapRows,
    ],
  );

  /* Update the rows of the main container based on the current widget's (dragging/resizing) bottom row */
  const updateDropTargetRows = useCallback(
    (widgetId: string, widgetBottomRow: number) => {
      if (canDropTargetExtend) {
        const newRows = calculateDropTargetRows(
          widgetId,
          widgetBottomRow,
          minRows,
          occupiedSpaces,
          canDropTargetExtend,
        );

        setRows(newRows);
        setColumnCanvasExtensionRows({
          rows: newRows + paddingToGU(Padding.y(props.padding)),
          dropTargetId: props.widgetId,
        });

        if (rows < newRows) {
          return Number.MAX_SAFE_INTEGER;
        }
        return rows;
      }
      return rows;
    },
    [
      canDropTargetExtend,
      rows,
      minRows,
      occupiedSpaces,
      setRows,
      setColumnCanvasExtensionRows,
      props.padding,
      props.widgetId,
    ],
  );

  const isChildFocused =
    !!singleSelectedWidget && singleSelectedWidget.parentId === props.widgetId;

  const canvasWidgets = useAppSelector(getWidgets);
  const appSettings = useAppSelector(getApplicationSettings);

  const allowedChildTypes = useMemo(() => {
    return getAllowedChildTypesInWidgetAncestry({
      widgetId: props.widgetId,
      canvasWidgets,
      registeredComponents: appSettings?.registeredComponents,
    });
  }, [appSettings?.registeredComponents, canvasWidgets, props.widgetId]);

  const getIsAllowedType = useWidgetAllowedDropTypeFn(
    allowedChildTypes,
    selectedWidgets,
  );

  const parentWidgetToSelect = getParentToOpenIfAny(
    props.widgetId,
    canvasWidgets,
  );
  const widgetNames = useAppSelector(getExistingWidgetNames);

  const getSelectedStackDragPositions = useGetSelectedStackDragPositions();

  const createOpenButton = useCreateOpenButton();

  const isChildResizing = !!isResizing && isChildFocused;
  const store = useStore<AppState>();
  // Make this component a drop target
  const [{ isExactlyOver, isOver, isAllowedType, stackDragPositions }, drop] =
    useDrop({
      accept: [
        ...WidgetTypesArr,
        ...Object.values(appSettings?.registeredComponents ?? {}).map(
          (component) => customComponentType(component.id),
        ),
      ],
      options: {
        arePropsEqual: () => {
          return true;
        },
      },
      drop(widget: WidgetPropsRuntime & Partial<WidgetConfigProps>, monitor) {
        if (isExactlyOver && isAllowedType) {
          const scaledCoord = scaledXYCoord(
            monitor.getSourceClientOffset() as XYCoord,
            canvasScaleFactor,
          );

          const scaledMouseCoord = scaledXYCoord(
            monitor.getClientOffset() as XYCoord,
            canvasScaleFactor,
          );
          const widgets = [
            monitor.getItem(),
            ...selectedWidgets.filter(
              (w) => w.widgetId !== monitor.getItem()?.widgetId,
            ),
          ];
          const newSize = findFreePosition({
            clientOffset: scaledCoord,
            colWidth: props.gridColumnSpace,
            rowHeight: props.gridRowSpace,
            widgets,
            dropTargetOffset: dropTargetOffsetRef.current,
            occupiedSpaces,
            parentRows: props.canExtend ? Number.MAX_SAFE_INTEGER : rows,
            parentCols: props.gridColumns,
            mouseOffset: scaledMouseCoord,
            stackDragPositions,
          });

          const updateWidgetParams = widgetOperationParams(
            widget,
            scaledCoord,
            dropTargetOffsetRef.current,
            props.gridColumnSpace,
            props.gridRowSpace,
            props.widgetId,
            props.gridColumns,
            selectedWidgets.length <= 1 && typeof newSize === "object"
              ? newSize
              : undefined,
          );

          // TODO(Layout) check dimension type
          const widgetBottomRow =
            updateWidgetParams.payload.position.top.value +
            (updateWidgetParams.payload.size?.height.value || widget.top.value);

          let nextName;
          if (
            updateWidgetParams.operation === WidgetOperations.WIDGET_CREATE &&
            (widget.type === WidgetTypes.MODAL_WIDGET ||
              widget.type === WidgetTypes.SLIDEOUT_WIDGET)
          ) {
            nextName = getNextEntityName(
              widget.type === WidgetTypes.MODAL_WIDGET ? "Modal" : "Slideout",
              widgetNames,
            );
            updateWidgetParams.payload.widgetName = nextName;
          }

          persistDropTargetRows(widget.widgetId, widgetBottomRow);
          updateWidget?.(
            updateWidgetParams.operation,
            updateWidgetParams.widgetId,
            updateWidgetParams.payload,
          );
          // FIXME: Remove this 2nd call and find a better way to update both the widget position and the canvas size at the same time
          // so that the canvas properly resizes at the same time the widget position changes
          // If we put this call before updateWidget, the drop interaction is very clunky
          persistDropTargetRows(widget.widgetId, widgetBottomRow);

          // putting this dispatch into a requestIdleCallback to improve drop performance
          const isNew =
            updateWidgetParams.operation === WidgetOperations.WIDGET_CREATE;
          runAfterWidgetOperationsAreDone(store, () => {
            if (isNew) {
              // Select the widget if it is a new widget
              selectWidgets?.([widget.widgetId]);
            } else {
              // Just scroll the widget into view if it is not a new widget
              scrollWidgetIntoView(widget.widgetId);
            }
          });

          // focus the canvas on drop, so that nudge events start registering
          document.getElementById(`drop-target-${props.widgetId}`)?.focus();

          if (
            widget.type === WidgetTypes.MODAL_WIDGET ||
            widget.type === WidgetTypes.SLIDEOUT_WIDGET
          ) {
            const [left, top] = getDropZoneOffsets(
              props.gridColumnSpace,
              props.gridRowSpace,
              scaledCoord,
              dropTargetOffsetRef.current,
            );

            if (nextName) {
              createOpenButton({
                position: {
                  left: Dimension.gridUnit(left),
                  top: Dimension.gridUnit(top),
                },
                size: {
                  height:
                    selectedWidgets.length <= 1 && typeof newSize === "object"
                      ? Dimension.build(
                          newSize.bottom - newSize.top,
                          widget.height.mode,
                        )
                      : widget.height,
                  width:
                    selectedWidgets.length <= 1 && typeof newSize === "object"
                      ? Dimension.build(
                          newSize.right - newSize.left,
                          widget.width.mode,
                        )
                      : widget.width,
                },
                parentWidgetId: props.widgetId,
                widgetToOpenInfo: {
                  name: nextName,
                  type: widget.type as WidgetTypes,
                },
              });
            }
          }
        }

        return undefined;
      },
      // Collect isOver for ui transforms when hovering over this component
      collect: (monitor: DropTargetMonitor) => {
        const item: WidgetPropsRuntime | undefined = monitor.getItem();
        return {
          isExactlyOver: monitor.isOver({ shallow: true }),
          isOver: monitor.isOver(),
          isAllowedType: getIsAllowedType(monitor.getItemType() as WidgetTypes),
          stackDragPositions:
            item?.widgetId && monitor.isOver()
              ? getSelectedStackDragPositions(item?.widgetId)
              : undefined,
        };
      },
      // Only allow drop if the drag object is directly over this component
      // As opposed to the drag object being over a child component, or outside the component bounds
      // Also only if the dropzone does not overlap any existing children
      canDrop: (widget, monitor) => {
        // Check if the draggable is the same as the dropTarget
        if (isExactlyOver && isAllowedType) {
          const isNewWidget = widget?.widgetName === undefined;

          const sourceClientOffset = monitor.getSourceClientOffset() as XYCoord;
          const clientOffset = monitor.getClientOffset() as XYCoord;

          // If a new widget, make sure we put the cursor during the drag set into the component bounds a bit so
          // it's not right on the edge of the component in the top left corner
          if (isNewWidget) {
            const newWidgetDragOffset = getNewWidgetDragOffset(
              props.gridColumnSpace,
            );
            clientOffset.x = clientOffset.x -= newWidgetDragOffset.x;
            clientOffset.y = clientOffset.y -= newWidgetDragOffset.y;
          }

          const widgets = [
            monitor.getItem(),
            ...selectedWidgets.filter(
              (w) => w.widgetId !== monitor.getItem()?.widgetId,
            ),
          ];
          const scaledMouseOffset = scaledXYCoord(
            clientOffset,
            canvasScaleFactor,
          );

          const scaledSourceClientOffset = scaledXYCoord(
            sourceClientOffset,
            canvasScaleFactor,
          );
          const availablePosition = findFreePosition({
            clientOffset: scaledSourceClientOffset,
            colWidth: props.gridColumnSpace,
            rowHeight: props.gridRowSpace,
            widgets,
            dropTargetOffset: dropTargetOffsetRef.current,
            occupiedSpaces: occupiedSpaces,
            parentRows: props.canExtend ? Number.MAX_SAFE_INTEGER : rows,
            parentCols: props.gridColumns,
            mouseOffset: scaledMouseOffset,
            stackDragPositions,
          });
          return Boolean(availablePosition);
        }
        return false;
      },
    });

  const handleBoundsUpdate = useCallback(
    (rect: DOMRect) => {
      if (
        rect.x !== dropTargetOffsetRef.current.x ||
        rect.y !== dropTargetOffsetRef.current.y
      ) {
        dropTargetOffsetRef.current = {
          x: rect.x,
          y: rect.y,
        };
      }
    },
    [dropTargetOffsetRef],
  );

  const shouldFocusRef = useRef(false);
  shouldFocusRef.current = !(isResizing || isDragging);

  const handleFocus = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      if (!shouldFocusRef.current) return;

      // Focus is allowed to propagate since the Grid depends on it
      if (
        parentWidgetToSelect &&
        parentWidgetToSelect.type === WidgetTypes.GRID_WIDGET
      ) {
        focusWidget && focusWidget(parentWidgetToSelect.widgetId);
        selectWidgets &&
          props.widgetId !== parentWidgetToSelect.widgetId &&
          selectWidgets([parentWidgetToSelect.widgetId]);
      } else if (
        props.type === WidgetTypes.CANVAS_WIDGET &&
        parentIsModalOrSlideout
      ) {
        const widgetIdToSelect = props.parentId;
        focusWidget && focusWidget(widgetIdToSelect);
        selectWidgets && selectWidgets([widgetIdToSelect]);
      }
    },
    [
      parentIsModalOrSlideout,
      props.type,
      focusWidget,
      parentWidgetToSelect,
      props.parentId,
      props.widgetId,
      selectWidgets,
    ],
  );

  const { setCurrentDropTarget, setCurrentDropTargetRows } =
    useWidgetDragResize();

  const currentDropTarget = useAppSelector(
    (state: AppState) => state.legacy.ui.widgetDragResize.currentDropTarget,
  );

  // If we are a section, we need to check if the currentDropTarget is a sibling because columns grow together
  const isSiblingOfDropTarget =
    currentDropTarget &&
    parentWidget.type === WidgetTypes.SECTION_WIDGET &&
    parentWidget.children?.includes(currentDropTarget);

  useEffect(() => {
    // This useEffect updates the rows of the container when the height changes for external reasons e.g. autoheight
    // If we are dragging/resizing, then height changes are likely from interaction with this component so do nothing
    const isAnUnrelatedDropTarget =
      !isSiblingOfDropTarget &&
      currentDropTarget !== props.widgetId &&
      currentDropTarget !== undefined;

    // Auto height widgets have their height changed externally and it can not be dragged so we should update the rows
    const notResizingOrDragging =
      !isDragging && (!isResizing || isResizingItemAutoHeight);

    if (notResizingOrDragging) {
      const snapRows = getCanvasGridRows(props.height, minHeight);
      setRows(snapRows);

      if (isAnUnrelatedDropTarget) {
        setColumnCanvasExtensionRows(null);
      }
    }
  }, [
    props.height,
    setRows,
    minHeight,
    isDragging,
    isResizing,
    setColumnCanvasExtensionRows,
    currentDropTarget,
    props.widgetId,
    isSiblingOfDropTarget,
    isResizingItemAutoHeight,
  ]);

  const wasDropTarget = useRef<boolean>(false);
  useEffect(() => {
    // Clear the local value when the currentDropTarget changes
    if (currentDropTarget !== props.widgetId && wasDropTarget.current) {
      const snapRows = getCanvasGridRows(props.height, minHeight);
      setRows(snapRows);
      setColumnCanvasExtensionRows(null);
    }
    wasDropTarget.current = currentDropTarget === props.widgetId;
  }, [
    setRows,
    currentDropTarget,
    props.height,
    minHeight,
    props.widgetId,
    props.widgetName,
    setColumnCanvasExtensionRows,
  ]);

  useEffect(() => {
    if (isDragging) {
      const isWidgetDraggedIntoBlankSpace =
        scrollIntoEmptySpace && currentDropTarget === undefined;

      if (isExactlyOver || isWidgetDraggedIntoBlankSpace) {
        setCurrentDropTarget(props.widgetId);
        setCurrentDropTargetRows(rows);
      }
    } else if (isResizing) {
      if (isChildResizing) {
        setCurrentDropTarget(props.widgetId);
        setCurrentDropTargetRows(rows);
      }
    } else {
      setCurrentDropTarget(undefined);
    }
  }, [
    currentDropTarget,
    isChildResizing,
    isDragging,
    isExactlyOver,
    isResizing,
    scrollIntoEmptySpace,
    props.widgetId,
    props.widgetName,
    rows,
    setCurrentDropTarget,
    setCurrentDropTargetRows,
  ]);

  const rowGU = Dimension.gridUnit(rows);
  const border = Dimension.px(getBorderThickness(props, theme) * 2);

  const rowHeightPx = heightToPixels(
    rowGU,
    props.padding?.top,
    props.padding?.bottom,
    border,
  );
  const minHeightPx = heightToPixels(
    minHeight,
    props.padding?.top,
    props.padding?.bottom,
    border,
  );

  const dslHeight = heightToPixels(
    props.height,
    props.padding?.top,
    props.padding?.bottom,
    border,
  );

  const height = useMemo(() => {
    const isSectionCanvas = parentWidget?.type === WidgetTypes.SECTION_WIDGET;

    return Math.max(
      rowHeightPx,
      minHeightPx,
      dslHeight,
      isSectionCanvas && columnCanvasExtensionRows?.rows
        ? heightToPixels(Dimension.gridUnit(columnCanvasExtensionRows.rows))
        : 0,
    );
  }, [
    rowHeightPx,
    minHeightPx,
    dslHeight,
    parentWidget?.type,
    columnCanvasExtensionRows?.rows,
  ]);

  const dropRef = !props.dropDisabled ? drop : undefined;

  // memoizing context values
  const contextValue = useMemo(() => {
    return {
      updateDropTargetRows,
      persistDropTargetRows,
      occupiedSpaces: occupiedSpaces,
    };
  }, [updateDropTargetRows, persistDropTargetRows, occupiedSpaces]);

  const [nudgeDirection, setNudgeDirection] = useState<{
    colShift: number;
    rowShift: number;
  }>({ colShift: 0, rowShift: 0 });

  const editorReadOnly = useAppSelector(getEditorReadOnly);
  useEffect(() => {
    if (editorReadOnly) return;
    const dropTargetElement = document.getElementById(
      `drop-target-${props.widgetId}`,
    );

    const handleKeyDown = (e: KeyboardEvent): void => {
      if (["ArrowUp", "ArrowDown", "ArrowRight", "ArrowLeft"].includes(e.key)) {
        if (!isNudgeDisabled) {
          e.preventDefault();
          let colShift =
            e.key === "ArrowLeft" ? -1 : e.key === "ArrowRight" ? 1 : 0;
          let rowShift =
            e.key === "ArrowUp" ? -1 : e.key === "ArrowDown" ? 1 : 0;
          if (e.shiftKey) {
            colShift *= 5;
            rowShift *= 5;
          }
          setNudgeDirection(() => ({ colShift, rowShift }));
        }
      }
    };

    const handleKeyUp = (e: KeyboardEvent): void => {
      if (["ArrowUp", "ArrowDown", "ArrowRight", "ArrowLeft"].includes(e.key)) {
        e.preventDefault();
        setNudgeDirection(() => ({ rowShift: 0, colShift: 0 }));
      }
    };

    if (dropTargetElement) {
      dropTargetElement.addEventListener("keydown", handleKeyDown);
      dropTargetElement.addEventListener("keyup", handleKeyUp);
    }

    return (): void => {
      if (dropTargetElement) {
        dropTargetElement.removeEventListener("keydown", handleKeyDown);
        dropTargetElement.removeEventListener("keyup", handleKeyUp);
      }
    };
  }, [setNudgeDirection, props.widgetId, isNudgeDisabled, editorReadOnly]);

  const nudgeSelectedWidgets = useCallback(
    (nudgeDirection: any) => {
      if (!selectedWidgets || !selectedWidgets[0]) return;

      const widget = selectedWidgets[0];

      if (widget.parentId !== props.widgetId) return;

      if (
        typeof widget?.left?.value === "number" &&
        typeof widget?.top?.value === "number"
      ) {
        const { colShift, rowShift } = nudgeDirection as {
          colShift: number;
          rowShift: number;
        };
        const leftColumn = Dimension.add(
          widget?.left,
          Dimension.gridUnit(colShift),
        ).asFirst();
        const topRow = Dimension.add(
          widget?.top,
          Dimension.gridUnit(rowShift),
        ).asFirst();

        const hasCollision = !isPositionFree({
          clientOffset: { colShift, rowShift },
          colWidth: props.gridColumnSpace,
          rowHeight: props.gridRowSpace,
          widgets: selectedWidgets,
          dropTargetOffset: { x: 0, y: 0 },
          occupiedSpaces: occupiedSpaces,
          parentRows: props.canExtend ? Number.MAX_SAFE_INTEGER : rows,
          parentCols: props.gridColumns,
          disableResize: true,
        });

        if (hasCollision) return;

        const updateWidgetParams = {
          operation: WidgetOperations.WIDGETS_MOVE,
          widgetId: widget?.widgetId,
          payload: {
            position: {
              left: leftColumn,
              top: topRow,
            },
            parentId: widget.parentId,
            newParentId: widget.parentId,
          } satisfies Omit<WidgetMove, "widgetId">,
        };
        const widgetBottomRow = topRow.value + widget.height.value;
        const extraRows = 0; // 0 to avoid a shift in the scrollbar
        persistDropTargetRows(widget.parentId, widgetBottomRow, extraRows);

        updateWidget?.(
          updateWidgetParams.operation,
          updateWidgetParams.widgetId,
          updateWidgetParams.payload,
        );
      }
    },
    [
      selectedWidgets,
      occupiedSpaces,
      rows,
      props.gridColumnSpace,
      props.gridRowSpace,
      props.widgetId,
      props.canExtend,
      props.gridColumns,
      updateWidget,
      persistDropTargetRows,
    ],
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (
      !isNudgeDisabled &&
      (nudgeDirection.colShift !== 0 || nudgeDirection.rowShift !== 0)
    )
      nudgeSelectedWidgets(nudgeDirection);
  }, [nudgeDirection, isNudgeDisabled]);

  const style = useMemo(
    () => ({
      height,
    }),
    [height],
  );

  const rawRef = useRef<HTMLDivElement | null>(null);
  useEdgeScrollEffect(rawRef.current, isDragging && isExactlyOver);

  const sharedRef = useCallback(
    (node: HTMLDivElement) => {
      rawRef.current = node;
      dropRef?.(node);
    },
    [dropRef],
  );

  return (
    <DropTargetContext.Provider value={contextValue}>
      <StyledDropTarget
        onClick={handleFocus}
        ref={sharedRef}
        style={style}
        columnWidth={props.gridColumnSpace}
        gridColumns={props.gridColumns}
        padding={props.padding}
        data-test="drop-target"
        id={generateDropTargetElementId(props.widgetId)}
        tabIndex={-1}
      >
        {props.children}
        <DragLayerComponent
          parentWidgetId={props.widgetId}
          canDropTargetExtend={canDropTargetExtend}
          delayExtension={!scrollIntoEmptySpace}
          parentRowHeight={props.gridRowSpace}
          parentColumnWidth={props.gridColumnSpace}
          visible={isExactlyOver || isChildResizing}
          isOver={isExactlyOver}
          isAllowedType={isAllowedType}
          occupiedSpaces={occupiedSpaces}
          onBoundsUpdate={handleBoundsUpdate}
          maxRows={maxRows}
          minRows={minRows}
          parentCols={props.gridColumns}
          isResizing={isChildResizing}
          force={isDragging && !isOver && !props.parentId}
          padding={props.padding}
        />
      </StyledDropTarget>
    </DropTargetContext.Provider>
  );
};

export default memo(DropTargetComponent);
