import { useMemo } from "react";
import styled from "styled-components";

const TextWrapper = styled.span`
  display: inline-flex;
  width: 100%;

  .middle-ellipsis-start,
  .middle-ellipsis-end {
    display: inline-block;
    max-width: 50%;
    overflow: hidden;
    white-space: pre;
  }

  .middle-ellipsis-start {
    text-overflow: ellipsis;
  }

  .middle-ellipsis-end {
    text-overflow: clip; // fallback for when the custom string is not supported
    text-overflow: ""; // use this instead of clip, it prevents characters from being cut off
    display: flex;
    justify-content: flex-end;
  }
`;

interface SpanTruncMiddleProps {
  text: string;
}

/** A span variant that truncates its text content in the middle (with ellipsis), if it would have otherwise overflown.
 * The width is determined by the width of the parent element.
 */
export const SpanTruncMiddle = (props: SpanTruncMiddleProps) => {
  // split the text into two fragments, the prefix and suffix, and put them into two
  // separate elements, with the first truncating at its end and the second at its start
  const [prefix, suffix] = useMemo(() => {
    const mid = (props.text.length + 1) >> 1;
    return [
      String(props.text).substring(0, mid),
      String(props.text).substring(mid),
    ];
  }, [props.text]);

  return (
    <TextWrapper>
      <span className="middle-ellipsis-start">{prefix}</span>
      <span className="middle-ellipsis-end">{suffix}</span>
    </TextWrapper>
  );
};
