import { xor, without, set } from "lodash";
import {
  updateWidgetProperties,
  UpdateWidgetPropertiesPayload,
} from "legacy/actions/controlActions";
import { WidgetAddChild } from "legacy/actions/pageActions";
import {
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import { WidgetTypes } from "legacy/constants/WidgetConstants";
import { FlattenedWidgetProps } from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import { WidgetMetadata } from "legacy/reducers/entityReducers/metaReducer";
import {
  mergeUpdatesWithBindingsOrTriggers,
  deleteWithBindingsOrTriggers,
} from "legacy/utils/DynamicBindingUtils";
import { Flags, FlagType } from "store/slices/featureFlags";
import { Flag } from "store/slices/featureFlags";
import { fastClone } from "utils/clone";
import { getDottedPathTo } from "utils/dottedPaths";
import { ColumnProperties, ColumnTypes } from "./TableComponent/Constants";
import { getAllTableColumnKeys } from "./TableComponent/TableHelpers";
import {
  getDefaultColumnProperties,
  getTableStyles,
  getMergedColumnOrder,
  columnHasNonDefaultProps,
} from "./TableComponent/TableUtilities";
import { PaginationTypes } from "./TableComponent/types";
import tablePropertyPaneConfig from "./TablePropertyPaneConfig";
import {
  TableWidgetProps,
  TableWidgetEvaluatedProps,
} from "./TableWidgetConstants";
import { insertDefaultTableData } from "./defaultDataProcessor";
import type { CanvasWidgetsReduxState, WidgetActionResponse } from "../Factory";
import type {
  DataTree,
  DataTreeWidget,
} from "legacy/entities/DataTree/dataTreeFactory";

export function getMetaPropertiesMap(): Record<string, any> {
  return {
    selectedRowIndex: undefined,
    selectedRowIndices: undefined,
    pageNo: 1,
    sortedColumn: undefined,
    searchText: undefined,
    filters: undefined,
    hiddenColumns: undefined,
    columnFreezes: undefined,
    tagsColorAssignment: undefined,
    editOverrides: undefined,
    currentEditFocus: undefined,
    currentEditDropdownSearchText: undefined,
    currentEditValue: undefined,
    deletedRowIndices: undefined,
    inserts: undefined,
  };
}

function getDerivedColumns(
  derivedColumns: Record<string, ColumnProperties>,
  tableColumnCount: number,
) {
  if (!derivedColumns) return [];
  //update index property of all columns in new derived columns
  return (
    Object.keys(derivedColumns)?.map((columnId: string, index: number) => {
      return {
        ...derivedColumns[columnId],
        index: index + tableColumnCount,
      };
    }) || []
  );
}

export function createTablePrimaryColumns(
  props: TableWidgetProps,
  tableData: Readonly<TableWidgetEvaluatedProps["tableData"]>,
  inserts: Readonly<TableWidgetEvaluatedProps["inserts"]>,
): Record<string, ColumnProperties> | undefined {
  const {
    primaryColumns = {},
    columnNameMap = {},
    columnTypeMap = {},
    derivedColumns = {},
    migrated,
  } = props;

  const previousColumnIds = Object.keys(primaryColumns);
  const tableColumns: Record<string, ColumnProperties> = {};
  //Get table level styles
  const tableStyles = getTableStyles(props);
  const columnKeys: string[] = getAllTableColumnKeys(
    tableData,
    // if there is no table data, fallback to the previous column ids
    tableData.length === 0 ? previousColumnIds : undefined,
  );
  // Generate default column properties for all columns
  // But do not replace existing columns with the same id
  for (let index = 0; index < columnKeys.length; index++) {
    const i = columnKeys[index];
    const prevIndex = previousColumnIds.indexOf(i);
    if (prevIndex > -1) {
      // we found an existing property with the same column id use the previous properties
      tableColumns[i] = primaryColumns[i];
    } else if (props.cachedColumnSettings?.[i]) {
      tableColumns[i] = props.cachedColumnSettings[i];
    } else {
      const columnProperties = getDefaultColumnProperties(
        i,
        index,
        props.widgetName,
      );
      if (migrated === false) {
        if ((columnNameMap as Record<string, string>)[i]) {
          columnProperties.label = columnNameMap[i];
        }
        if (
          (
            columnTypeMap as Record<
              string,
              {
                type: ColumnTypes;
                inputFormat?: string;
                format?: string;
                timezone?: string;
                displayTimezone?: string;
              }
            >
          )[i]
        ) {
          columnProperties.columnType = columnTypeMap[i].type;
          columnProperties.inputFormat = columnTypeMap[i].inputFormat;
          columnProperties.outputFormat = columnTypeMap[i].format;
          columnProperties.timezone = columnTypeMap[i].timezone;
          columnProperties.displayTimezone = columnTypeMap[i].displayTimezone;
        }
      }
      //add column properties along with table level styles
      tableColumns[columnProperties.id] = {
        ...columnProperties,
        ...tableStyles,
      };
    }
  }
  // Get derived columns
  const updatedDerivedColumns = getDerivedColumns(
    derivedColumns,
    Object.keys(tableColumns).length,
  );

  //add derived columns to primary columns
  updatedDerivedColumns.forEach((derivedColumn: ColumnProperties) => {
    tableColumns[derivedColumn.id] = derivedColumn;
  });

  const newColumnIds = Object.keys(tableColumns);
  if (xor(previousColumnIds, newColumnIds).length > 0) return tableColumns;
  else return;
}

function updateColumnProperties(
  props: TableWidgetProps,
  tableColumns?: Record<string, ColumnProperties>,
  isDeepBindingsFeatureFlagEnabled?: FlagType, // TODO: remove after FF is on
) {
  const { primaryColumns = {} } = props;
  const { columnOrder, migrated } = props;
  if (tableColumns) {
    const previousColumnIds = Object.keys(primaryColumns);
    const newColumnIds = Object.keys(tableColumns);

    if (xor(previousColumnIds, newColumnIds).length > 0) {
      const columnIdsToAdd = without(newColumnIds, ...previousColumnIds);

      const propertiesToAdd: Record<string, unknown> = {};
      columnIdsToAdd.forEach((id: string) => {
        Object.entries(tableColumns[id]).forEach(([key, value]) => {
          propertiesToAdd[
            `primaryColumns${getDottedPathTo(id)}${getDottedPathTo(key)}`
          ] = value;
        });
      });

      // If new columnOrders have different values from the original columnOrders
      if (xor(newColumnIds, columnOrder).length > 0) {
        const sortedIds = getMergedColumnOrder(columnOrder ?? [], newColumnIds);
        propertiesToAdd["columnOrder"] = sortedIds;
      }

      const pathsToDelete: string[] = [];
      if (migrated === false) {
        propertiesToAdd["migrated"] = true;
      }
      let updatedWidget = fastClone(props);
      const propertyUpdates = mergeUpdatesWithBindingsOrTriggers(
        props,
        tablePropertyPaneConfig,
        propertiesToAdd,
        isDeepBindingsFeatureFlagEnabled, // TODO: remove after FF is on
      ) as unknown as Record<string, unknown>;
      // We loop over all updates
      Object.entries(propertyUpdates).forEach(
        ([propertyPath, propertyValue]) => {
          // since property paths could be nested, we use lodash set method
          set(updatedWidget, propertyPath, propertyValue);
        },
      );
      const columnsIdsToDelete = without(previousColumnIds, ...newColumnIds);
      columnsIdsToDelete.forEach((id: string) => {
        pathsToDelete.push(`primaryColumns${getDottedPathTo(id)}`);
      });
      if (pathsToDelete.length) {
        updatedWidget = deleteWithBindingsOrTriggers(
          updatedWidget,
          pathsToDelete,
        ) as unknown as TableWidgetProps;
      }
      // add any delete column properties to the cachedColumnSettings
      columnsIdsToDelete.forEach((id: string) => {
        if (
          columnHasNonDefaultProps(props.primaryColumns[id], props.widgetName)
        ) {
          if (!updatedWidget.cachedColumnSettings) {
            updatedWidget.cachedColumnSettings = {};
          }
          updatedWidget.cachedColumnSettings[id] = props.primaryColumns[id];
        }
      });
      // delete any column properties from cachedColumnSettings that are in newColumnIds
      newColumnIds.forEach((id: string) => {
        delete updatedWidget?.cachedColumnSettings?.[id];
      });

      // TODO: do we need this? Hooks cause a layout update after running anyway
      // yield put(setSingleWidget(props.widgetId, updatedWidget, false, false));
      return updatedWidget;
    }
  }
}

export function initializeTable(
  widgetId: string,
  props: TableWidgetProps,
  evaluatedWidget: Readonly<TableWidgetEvaluatedProps>,
  isDeepBindingsFeatureFlagEnabled?: FlagType, // TODO: remove after FF is on
) {
  const { tableData } = evaluatedWidget;
  let newPrimaryColumns;
  // When we have tableData, the primaryColumns order is unlikely to change
  // When we don't have tableData primaryColumns will not be available, so let's let it be.

  if (tableData.length > 0) {
    newPrimaryColumns = createTablePrimaryColumns(
      props,
      evaluatedWidget.tableData,
      evaluatedWidget.inserts,
    );
  }
  if (!newPrimaryColumns) {
    return props;
  } else {
    const widget = updateColumnProperties(
      props,
      newPrimaryColumns,
      isDeepBindingsFeatureFlagEnabled, // TODO: remove after FF is on
    );
    return widget || props;
  }
}

function updateExisting(
  widgetId: string,
  props: TableWidgetProps,
  evaluatedWidget: Readonly<TableWidgetEvaluatedProps>,
  evaluatedWidgets: Record<string, DataTreeWidget>,
  isDeepBindingsFeatureFlagEnabled?: FlagType, // TODO: remove after FF is on
): WidgetActionResponse {
  const previousWidget = evaluatedWidgets[widgetId] as unknown as
    | TableWidgetEvaluatedProps
    | undefined;

  let hasUpdates = false;
  let updatedWidget: TableWidgetProps | undefined = fastClone(props);

  if (!previousWidget) {
    // Make sure that widget.primaryColumns is set
    updatedWidget = initializeTable(
      widgetId,
      props,
      evaluatedWidget,
      isDeepBindingsFeatureFlagEnabled, // TODO: remove after FF is on
    );
    hasUpdates = true;
  }

  const { primaryColumns = {} } = updatedWidget;

  const widgetMetaUpdates: WidgetActionResponse["widgetMetaUpdates"] = [];

  // Check if data is modifed by comparing the stringified versions of the previous and next tableData
  // But don't remove columns when the table becomes empty
  const tableDataModified =
    previousWidget &&
    evaluatedWidget.tableData.length &&
    JSON.stringify(evaluatedWidget.tableData) !==
      JSON.stringify(previousWidget?.tableData);

  // If the user has changed the tableData OR
  // The binding has returned a new value,
  // but not if this is the first time rendering the table
  if (tableDataModified) {
    // Get columns keys from this.props.tableData
    const columnIds: string[] = getAllTableColumnKeys(
      evaluatedWidget.tableData,
    );
    // Get column keys from columns except for derivedColumns
    const primaryColumnIds = Object.keys(primaryColumns).filter(
      (id: string) => {
        return !primaryColumns[id].isDerived; // Filter out the derived columns
      },
    );
    // If the keys which exist in the tableData are different from the ones available in primaryColumns
    if (xor(columnIds, primaryColumnIds).length > 0) {
      const newTableColumns = createTablePrimaryColumns(
        updatedWidget,
        evaluatedWidget.tableData,
        evaluatedWidget.inserts,
      );
      // This updates the widget
      if (!newTableColumns) {
        // Don't modify the filteredTableData if primaryColumns isn't changing
        return {
          widgetUpdates: [],
          widgetMetaUpdates: [],
        };
      }
      updatedWidget = updateColumnProperties(
        updatedWidget,
        newTableColumns,
        isDeepBindingsFeatureFlagEnabled, // TODO: remove after FF is on
      );
      hasUpdates = true;
    }

    // Since the table config has changed, we need to reinitialize the selectedRow, filters, etc.
    // For server side pagnination, we need to keep the pageNumber
    if (props.pageType === PaginationTypes.SERVER_SIDE) {
      const allMetaProps = Object.keys(getMetaPropertiesMap());
      widgetMetaUpdates.push({
        widgetId,
        reset: true,
        resetOptions: {
          propertyNames: allMetaProps.filter((prop) => prop !== "pageNo"),
        },
      });
    } else {
      widgetMetaUpdates.push({
        widgetId,
        reset: true,
      });
    }
  }

  return {
    widgetUpdates: hasUpdates
      ? [
          {
            widgetId,
            widget: updatedWidget as unknown as FlattenedWidgetProps,
          },
        ]
      : [],
    widgetMetaUpdates,
  };
}

export function applyActionHook(params: {
  widgetId: string;
  widgets: Readonly<CanvasWidgetsReduxState>;
  existingWidgetMetaProps: WidgetMetadata;
  evaluatedWidgets: Record<string, DataTreeWidget>;
  action: ReduxAction<
    DataTree | WidgetAddChild | UpdateWidgetPropertiesPayload
  >;
  flags: Flags;
}): WidgetActionResponse {
  const {
    widgetId,
    widgets,
    action,
    existingWidgetMetaProps,
    evaluatedWidgets,
  } = params;

  if (widgets[widgetId].type !== WidgetTypes.TABLE_WIDGET) {
    return {
      widgetUpdates: [],
      widgetMetaUpdates: [],
    };
  }

  const widgetMetaUpdates: WidgetActionResponse["widgetMetaUpdates"] = [];
  const widgetUpdates: WidgetActionResponse["widgetUpdates"] = [];

  switch (action.type) {
    case updateWidgetProperties.type: {
      if (
        !updateWidgetProperties.match(action) ||
        action.payload.widgetId !== widgetId
      ) {
        return {
          widgetUpdates: [],
          widgetMetaUpdates: [],
        };
      }
      const updatePayload = action.payload;
      const updates = updatePayload.updates;
      if (
        Object.keys(updates).some((key) => key.startsWith("primaryColumns")) ||
        updates.columnOrder
      ) {
        // any column related changes should clear the column related meta
        widgetMetaUpdates.push({
          widgetId,
          updates: {
            hiddenColumns: undefined,
          },
        });
      }
      if (
        Object.keys(updates).some(
          (key) => key.startsWith("primaryColumns") && key.endsWith("isFrozen"),
        )
      ) {
        const changedColumnFreezes = Object.entries(updates).reduce(
          (accum: Record<string, boolean>, [key, value]) => {
            if (key.startsWith("primaryColumns") && key.endsWith("isFrozen")) {
              const columnName = key.split(".")[1];
              accum[columnName] = value as unknown as boolean;
            }
            return accum;
          },
          {},
        );
        widgetMetaUpdates.push({
          widgetId,
          updates: {
            columnFreezes: {
              ...((existingWidgetMetaProps?.columnFreezes as Record<
                string,
                boolean
              >) ?? {}),
              ...changedColumnFreezes,
            },
          },
        });
      }
      break;
    }
    case ReduxActionTypes.TREE_WILL_UPDATE: {
      const evaluatedWidget: TableWidgetEvaluatedProps = (
        action.payload as DataTree
      ).PAGE[widgets[widgetId].widgetName] as any;
      if (!evaluatedWidget || evaluatedWidget.isLoading) {
        break;
      }

      const result = updateExisting(
        widgetId,
        widgets[widgetId] as TableWidgetProps,
        evaluatedWidget,
        evaluatedWidgets,
        params.flags[Flag.ENABLE_DEEP_BINDINGS_PATHS], // To be removed
      );
      widgetUpdates.push(...(result?.widgetUpdates ?? []));
      widgetMetaUpdates.push(...(result?.widgetMetaUpdates ?? []));
      break;
    }
    case ReduxActionTypes.WIDGET_CREATE: {
      if ((action.payload as WidgetAddChild).newWidgetId === widgetId) {
        widgetUpdates.push(...insertDefaultTableData(widgetId, widgets));
      }
      break;
    }
  }

  return {
    widgetUpdates,
    widgetMetaUpdates,
  };
}
