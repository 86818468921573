import { ReactNode, CSSProperties, useMemo, useContext } from "react";
import { useSelector } from "react-redux";
import { CanvasDefaults, CanvasLayout } from "legacy/constants/WidgetConstants";
import MarginOverlay from "legacy/pages/Editor/CanvasArenas/MarginOverlay";
import { SpacingOverlayItem } from "legacy/pages/Editor/CanvasArenas/SpacingOverlay";
import { APP_MODE } from "legacy/reducers/types";
import { selectIsResizing } from "legacy/selectors/dndSelectors";
import {
  getFlattenedCanvasWidget,
  getParentHasFillParentChild,
  getParentNonDetachedChildren,
} from "legacy/selectors/editorSelectors";
import { useAppSelector } from "store/helpers";
import { getComponentDimensions } from "utils/size";
import { type WidgetPropsRuntime } from "../BaseWidget";
import { DropTargetContext } from "../base/DropTargetUtils";
import { getFlexStyles } from "../base/sizing";
import {
  getApplicableMinWidth,
  getApplicableMaxWidth,
} from "../base/sizing/canvasWidthUtil";
import type { AppState } from "store/types";

type Props = {
  children: ReactNode;
  widgetProps: WidgetPropsRuntime;
  appMode: APP_MODE;
};

/*
 *  When resizing items in a stack, there are a few considerations:
 *  1. The user may drag the resize handle along the axis of the container.
 *     This means that the sibling of our item being resized must move to accommodate.
 *     a. if an item in a vstack is made shorter, we don't need to move the siblings -
 *        we show the original bounding box of the resized item, and everything stays in place while the resize occurs
 *     b. if an item in a vstack is made bigger, we do need to move the siblings. Subsequent siblings need to be moved down,
 *        or, in a bottom-aligned container, preceding siblingts need to be moved up.
 *  2. The user may drag the resize handle along the alignment axis of the container.
 *     a. If the user drags the resize handle out of the stack,
 *        (e.g. in a Left-aligned vstack, the user drags the leftmost handle to the left),
 *        we obviously can't have the resized item begin to escape the stack, so we compensate by extending the item to the right.
 */
const ResizingStackItem = (props: Props) => {
  const { children, widgetProps } = props;
  const { temporaryResizeValue } = useContext(DropTargetContext);
  const isResizing = useAppSelector(selectIsResizing);
  const overrideSizing =
    isResizing &&
    temporaryResizeValue &&
    temporaryResizeValue?.widgetId === widgetProps.widgetId;

  const parentWidget = useSelector((state: AppState) =>
    getFlattenedCanvasWidget(state, widgetProps.parentId || ""),
  );

  const parentNonDetachedChildren = useSelector((state: AppState) =>
    getParentNonDetachedChildren(state, widgetProps.parentId || ""),
  );

  const parentHasFillParentChild = useSelector((state: AppState) =>
    getParentHasFillParentChild(state, widgetProps.parentId || ""),
  );

  const componentSize = useMemo(
    () => getComponentDimensions(widgetProps),
    [widgetProps],
  );

  const resizingHeight = Math.max(
    temporaryResizeValue?.size?.height ?? 0,
    componentSize.componentHeight,
  );

  const resizingWidth = Math.max(
    temporaryResizeValue?.size?.width ?? 0,
    componentSize.componentWidth,
  );

  const resizedWidgetWrapperInfo = useMemo(() => {
    if (!overrideSizing)
      return {
        style: {
          display: "contents", // this is needed because positioned container could have flex properties that we want to respect
        },
      };

    const { size, position } = temporaryResizeValue;

    if (widgetProps.parentLayout === CanvasLayout.VSTACK) {
      return {
        style: {
          display: "flex",
          position: "relative",
          flexDirection: "column",
          width: Math.max(size.width, componentSize.componentWidth),
          height: Math.max(size.height, componentSize.componentHeight),
          alignItems: position.x === 0 ? "flex-start" : "flex-end", // y != 0 when dragging the top handles
          justifyContent: position.y === 0 ? "flex-start" : "flex-end", // x != 0 when dragging the left handles
          // Left and top handle dragging uses flex-end because of the way this interacts with how our child components
          // handle themselves during the resize. I don't have an intuitive explanation for why this works, but it does.
        } as CSSProperties,
      };
    } else {
      return {
        style: {
          display: "flex",
          position: "relative",
          flexDirection: "row",
          width: Math.max(size.width, componentSize.componentWidth),
          height: Math.max(size.height, componentSize.componentHeight),
          alignItems: position.y === 0 ? "flex-start" : "flex-end", // y != 0 when dragging the top handles
          justifyContent: position.x === 0 ? "flex-start" : "flex-end", // x != 0 when dragging the left handles
        } as CSSProperties,
      };
    }
  }, [
    overrideSizing,
    temporaryResizeValue,
    widgetProps.parentLayout,
    componentSize.componentWidth,
    componentSize.componentHeight,
  ]);

  const style = useMemo(() => {
    const applicableWidthProps = {
      width: widgetProps.width,
      minWidth: widgetProps.minWidth,
      maxWidth: widgetProps.maxWidth,
      parentColumnSpace: widgetProps.parentColumnSpace,
      parentLayout: widgetProps.parentLayout,
    };
    const minWidth = getApplicableMinWidth(applicableWidthProps);
    const maxWidth = getApplicableMaxWidth(applicableWidthProps);

    return {
      ...resizedWidgetWrapperInfo?.style,
      ...getFlexStyles({
        height: widgetProps.height,
        heightPx: resizingHeight,
        minHeight: widgetProps.minHeight,
        maxHeight: widgetProps.maxHeight,
        minWidth,
        maxWidth,
        width: widgetProps.width,
        widthPx: resizingWidth,
        parentColumnSpace: widgetProps.parentColumnSpace,
        parentDirection:
          widgetProps.parentLayout === CanvasLayout.VSTACK ? "column" : "row",
        margin: widgetProps.margin,
      }),
    } satisfies CSSProperties;
  }, [
    resizedWidgetWrapperInfo?.style,
    widgetProps.height,
    widgetProps.minHeight,
    widgetProps.maxHeight,
    widgetProps.minWidth,
    widgetProps.maxWidth,
    widgetProps.width,
    widgetProps.parentColumnSpace,
    widgetProps.parentLayout,
    widgetProps.margin,
    resizingHeight,
    resizingWidth,
  ]);

  const spacing = parentWidget.spacing ?? CanvasDefaults.SPACING;

  const isFirstChild = parentNonDetachedChildren[0] === widgetProps.widgetId;
  const isLastChild =
    parentNonDetachedChildren[(parentNonDetachedChildren || []).length - 1] ===
    widgetProps.widgetId;

  return (
    <>
      {isFirstChild && (
        <SpacingOverlayItem
          layout={widgetProps.parentLayout || CanvasLayout.VSTACK}
          spacing={spacing}
          parentId={widgetProps.parentId}
          distribution={parentWidget.distribution}
          alignment={parentWidget.alignment}
          isLeadingOrTrailingSpacer={true}
          parentHasFillParentChild={parentHasFillParentChild}
        />
      )}
      <div style={style} data-test="resizing-stack-item">
        {overrideSizing && (
          <MarginOverlay
            widgetId={widgetProps.widgetId}
            parentId={widgetProps.parentId}
            margin={widgetProps.margin}
          />
        )}
        {children}
      </div>
      <SpacingOverlayItem
        layout={widgetProps.parentLayout || CanvasLayout.VSTACK}
        spacing={spacing}
        parentId={widgetProps.parentId}
        distribution={parentWidget.distribution}
        alignment={parentWidget.alignment}
        isLeadingOrTrailingSpacer={isLastChild}
        parentHasFillParentChild={parentHasFillParentChild}
      />
    </>
  );
};
export default ResizingStackItem;
