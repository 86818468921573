import { Padding } from "@superblocksteam/shared";
import { useMemo } from "react";
import {
  GAP_BETWEEN_INPUT_AND_INLINE_ERROR_MESSAGE,
  INLINE_ERROR_MESSAGE_HEIGHT,
} from "legacy/components/editorComponents/ErrorInlineMessage";
import { ErrorMessagePlacement } from "legacy/constants/WidgetConstants";
import { getAppMode } from "legacy/selectors/applicationSelectors";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { INPUT_PADDING } from "legacy/themes/constants";
import { useAppSelector } from "store/helpers";
import { generateInputBorderOnHoverColor } from "../Shared/InputUtils";
import { DEFAULT_ICON_SIZE, getInputIconSize } from "../shared";
import { useStyleClassNames, useTypographyStyling } from "../typographyHooks";
import { getLineHeightInPxFromTextStyle } from "../typographyUtils";
import InputComponent from "./InputComponent";
import {
  DEFAULT_INPUT_WIDGET_INPUT_STYLE_VARIANT,
  DEFAULT_INPUT_WIDGET_LABEL_STYLE_VARIANT,
} from "./InputWidgetConstants";
import { getIconTop } from "./utils";
import type { InputComponentWithLayoutManagedProps } from "./types";

export const InputComponentWithLayoutManaged = (
  props: InputComponentWithLayoutManagedProps,
) => {
  const appMode = useAppSelector(getAppMode);
  const theme = useAppSelector(selectGeneratedTheme);

  const inputBaseStyle = useMemo(() => {
    return props.inputProps?.backgroundColor
      ? { backgroundColor: props.inputProps.backgroundColor }
      : {};
  }, [props.inputProps?.backgroundColor]);

  const labelProps = useTypographyStyling({
    textStyle: props.labelProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_INPUT_WIDGET_LABEL_STYLE_VARIANT,
    applyClassNameStylesToStyle: true,
  });

  const inputProps = useTypographyStyling({
    textStyle: props.inputProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_INPUT_WIDGET_INPUT_STYLE_VARIANT,
    applyClassNameStylesToStyle: true,
    priorityStyle: inputBaseStyle,
  });

  const labelClass = useStyleClassNames({
    textStyleVariant: labelProps.textStyleVariant, // Has fallback in place
    isLoading: props.isLoading,
    isDisabled: props.isDisabled,
    type: "label",
  });

  const inputClass = useStyleClassNames({
    textStyleVariant: inputProps?.textStyleVariant, // Has fallback in place
    isLoading: props.isLoading,
    isDisabled: props.isDisabled,
    type: "input",
  });

  const inputLineHeightPx = getLineHeightInPxFromTextStyle({
    textStyleVariant: inputProps?.textStyleVariant,
    nestedProps: props.inputProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_INPUT_WIDGET_INPUT_STYLE_VARIANT,
    typographies: theme.typographies,
  });

  const labelLineHeight =
    props.isVertical && props.label
      ? getLineHeightInPxFromTextStyle({
          textStyleVariant: labelProps.textStyleVariant,
          nestedProps: props.labelProps?.textStyle,
          defaultTextStyleVariant: DEFAULT_INPUT_WIDGET_LABEL_STYLE_VARIANT,
          typographies: theme.typographies,
        })
      : 0;

  const inputGroupStylingProps = useMemo(() => {
    return {
      border: props.inputProps?.border,
      fallbackBorderColor: theme.colors.neutral100, // Adapts according to theme's dark/light mode
      borderRadius: props.inputProps?.borderRadius,
      fallbackBorderRadius: theme.borderRadius, // InputGroups (inputs w/ prefix/suffix addons) don't inherit theme styles, we style them at InputComponent level
      borderColorOnHover: generateInputBorderOnHoverColor(
        props.inputProps?.border,
        theme.colors.primary500,
        theme.colors,
      ),
      defaultBorderWidth: theme.defaultBorder.borderWidth,
      padding: props.inputProps?.padding,
    };
  }, [
    props.inputProps?.border,
    props.inputProps?.borderRadius,
    theme.borderRadius,
    theme.colors,
    theme.defaultBorder,
    props.inputProps?.padding,
  ]);

  const padding = props.inputProps?.padding ?? INPUT_PADDING;
  const inlineErrorHeight =
    props.errorMessagePlacement === ErrorMessagePlacement.INLINE
      ? INLINE_ERROR_MESSAGE_HEIGHT + GAP_BETWEEN_INPUT_AND_INLINE_ERROR_MESSAGE
      : 0;

  const isMultiline =
    props.inputType === "TEXT" &&
    props.componentHeight -
      Padding.y(padding).value -
      labelLineHeight -
      inlineErrorHeight >=
      inputLineHeightPx * 2;

  const iconSize = useMemo(
    () =>
      getInputIconSize({
        width: props.width,
        componentWidth: props.componentWidth,
        textStyle: props.inputProps?.textStyle,
        padding: props.inputProps?.padding,
        generatedTheme: theme,
      }),
    [
      props.width,
      props.componentWidth,
      props.inputProps?.textStyle,
      props.inputProps?.padding,
      theme,
    ],
  );
  const inputStyleOverride = inputProps.style;
  const iconTop = useMemo(
    () =>
      getIconTop({
        inputProps: props.inputProps,
        inputStyleOverride,
        multiline: isMultiline,
        iconHeight: iconSize ?? DEFAULT_ICON_SIZE,
        inputHeight:
          props.componentHeight - labelLineHeight - inlineErrorHeight,
        generatedTheme: theme,
      }),
    [
      props.inputProps,
      inputStyleOverride,
      isMultiline,
      iconSize,
      props.componentHeight,
      labelLineHeight,
      inlineErrorHeight,
      theme,
    ],
  );

  return (
    <InputComponent
      {...props}
      isDisabled={props.isDisabled}
      inputClassName={inputClass}
      inputStyleOverride={inputStyleOverride}
      labelClassName={labelClass}
      multiline={isMultiline}
      labelStyleOverride={labelProps.style}
      labelWidth={props.labelProps?.width}
      inputGroupStylingProps={inputGroupStylingProps}
      appMode={appMode}
      iconTop={iconTop}
      iconSize={iconSize}
    />
  );
};
