import { InputNumber, InputNumberProps } from "antd";
import { clsx } from "clsx";
import { useEffect } from "react";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

const inputStyle = styleAsClass`
  width: 100%;

  .ant-input-number {
    border-right: 0;

    &:focus, &:active {
        box-shadow: none;
        border-color: ${colors.ACCENT_BLUE_500};
    }
    &.ant-input-number-focused {
        border-color: ${colors.ACCENT_BLUE_500};
        box-shadow: none;
    }
    & input {
      font-size: 12px;
    }
  }
  .ant-input-number-group-addon {
    font-size: 12px;
    background: transparent;
    transition: all 0.2s;
    color: transparent;
    padding: 0px 8px 0px 2px;
  }
  &:hover {
    .ant-input-number {
        border-color: ${colors.ACCENT_BLUE_500};
    }
    .ant-input-number-group-addon {
        color: ${colors.GREY_300};
        border-color: ${colors.ACCENT_BLUE_500};
    }
  }
  .ant-input-number:focus, .ant-input-number-focused + .ant-input-number-group-addon {
        color: ${colors.GREY_300};
        border-color: ${colors.ACCENT_BLUE_500};
    }

  .px-addon {
    height: 30px;
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: flex-end;
  }

  .ant-input-number-input {
    padding-left: 8px;
  }
`;

export const PixelInput = (
  props: InputNumberProps<number> & {
    showResetToDefault?: boolean;
    dataTest?: string;
  },
) => {
  const { showResetToDefault, onBlur, dataTest, ...rest } = props;

  useEffect(() => {
    // call onBlur when component unmounts to flush changes
    return () => {
      onBlur?.(null as any);
    };
  }, [onBlur]);

  return (
    <InputNumber
      {...rest}
      onBlur={onBlur}
      precision={0}
      data-test={dataTest}
      addonAfter={
        <div className="px-addon">
          <span>px</span>
        </div>
      }
      controls={false}
      className={clsx(inputStyle, props.className)}
    />
  );
};
