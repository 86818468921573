import { useCallback, useRef } from "react";
import { useStore } from "react-redux";
import { useFeatureFlag } from "hooks/ui";
import useInsertionIndexes from "hooks/ui/useInsertionIndexes";
import { WidgetTypes } from "legacy/constants/WidgetConstants";
import { useWidgetSelection } from "legacy/hooks/dragResizeHooks";
import { getParentToOpenIfAny } from "legacy/hooks/useClickOpenPropPane";
import {
  selectIsDragging,
  selectIsResizing,
} from "legacy/selectors/dndSelectors";
import { getWidgets } from "legacy/selectors/entitiesSelector";
import {
  getIsMultipleWidgetsSelected,
  getSingleSelectedWidgetId,
} from "legacy/selectors/sagaSelectors";
import { Flag } from "store/slices/featureFlags";
import { registerIframeClickEvent } from "utils/iframeClickHandler";
import type { WidgetProps } from "../BaseWidget";
import type { StaticWidgetProps } from "../shared";
import type { AppState } from "store/types";

type Props = {
  deselectOnSecondClick?: boolean;
  focusDisabled?: boolean;
  disabled?: boolean;
  requireDoubleClick?: boolean;
} & (StaticWidgetProps<unknown> | WidgetProps);

export const useWidgetFocusHandlers = (props: Props) => {
  const store = useStore<AppState>();
  const { selectWidgets, focusWidget, unfocusWidget } = useWidgetSelection();
  const getInsertionIndexes = useInsertionIndexes();
  const enableGrouping = useFeatureFlag(Flag.LAYOUTS_ENABLE_GROUPING);

  // Keep props in refs to prevent handler recreation
  const stableRefs = useRef({
    widgetId: props.widgetId,
    requireDoubleClick: props.requireDoubleClick,
    deselectOnSecondClick: props.deselectOnSecondClick,
    focusDisabled: props.focusDisabled,
    enableGrouping,
    selectWidgets,
    focusWidget,
    unfocusWidget,
    getInsertionIndexes,
  });
  stableRefs.current.widgetId = props.widgetId;
  stableRefs.current.requireDoubleClick = props.requireDoubleClick;
  stableRefs.current.deselectOnSecondClick = props.deselectOnSecondClick;
  stableRefs.current.focusDisabled = props.focusDisabled;
  stableRefs.current.enableGrouping = enableGrouping;
  stableRefs.current.selectWidgets = selectWidgets;
  stableRefs.current.focusWidget = focusWidget;
  stableRefs.current.unfocusWidget = unfocusWidget;
  stableRefs.current.getInsertionIndexes = getInsertionIndexes;

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const handleSelectionClick = useCallback(
    (e: any, requireDoubleClick?: boolean) => {
      const state = store.getState();
      const isDragging = selectIsDragging(state);
      const isResizing = selectIsResizing(state);
      const focusedWidget = state.legacy.ui.widgetDragResize.focusedWidgetId;
      const selectedWidget = getSingleSelectedWidgetId(state);
      const canvasWidgets = getWidgets(state);
      const { widgetId, deselectOnSecondClick } = stableRefs.current;

      const parentWidgetToSelect = getParentToOpenIfAny(
        widgetId,
        canvasWidgets,
      );

      if (isDragging || isResizing || requireDoubleClick) {
        e.stopPropagation();
        return;
      }

      if (
        !parentWidgetToSelect ||
        parentWidgetToSelect.type !== WidgetTypes.GRID_WIDGET
      ) {
        e.stopPropagation();
      }

      focusedWidget !== widgetId && focusWidget?.(widgetId);

      if (selectedWidget === widgetId && deselectOnSecondClick) {
        selectWidgets?.([]);
      } else if (selectedWidget !== widgetId) {
        selectWidgets?.([widgetId]);
      }
    },
    [store, focusWidget, selectWidgets],
  );

  const handleClick = useCallback(
    (e: any) => {
      const { widgetId, requireDoubleClick, getInsertionIndexes } =
        stableRefs.current;

      handleSelectionClick(e, requireDoubleClick);
      const insertionIndexes = getInsertionIndexes(
        widgetId,
        e.clientX,
        e.clientY,
      );
      registerIframeClickEvent(e, widgetId, insertionIndexes);
    },
    [handleSelectionClick],
  );

  const handleContextMenuClick = useCallback(
    (e: React.MouseEvent) => {
      const state = store.getState();
      const isMultipleWidgetsSelected = getIsMultipleWidgetsSelected(state);
      const { widgetId, enableGrouping, getInsertionIndexes } =
        stableRefs.current;

      if (!enableGrouping || !isMultipleWidgetsSelected) {
        handleSelectionClick(e, false);
      }

      e.preventDefault();
      e.stopPropagation();
      const insertionIndexes = getInsertionIndexes(
        widgetId,
        e.clientX,
        e.clientY,
      );
      registerIframeClickEvent(e, widgetId, insertionIndexes);
    },
    [store, handleSelectionClick],
  );

  const handleDoubleClick = useCallback(
    (e: React.MouseEvent) => {
      const state = store.getState();
      const { widgetId, requireDoubleClick, focusWidget, selectWidgets } =
        stableRefs.current;
      const focusedWidget = state.legacy.ui.widgetDragResize.focusedWidgetId;
      const selectedWidget = getSingleSelectedWidgetId(state);

      if (requireDoubleClick) {
        focusedWidget !== widgetId && focusWidget?.(widgetId);
        selectedWidget !== widgetId && selectWidgets?.([widgetId]);
      }
    },
    [store],
  );

  const handleMouseOver = useCallback(
    (e: any) => {
      const state = store.getState();
      const { widgetId, focusDisabled, focusWidget } = stableRefs.current;
      const focusedWidget = state.legacy.ui.widgetDragResize.focusedWidgetId;

      !focusDisabled &&
        focusWidget &&
        focusedWidget !== widgetId &&
        focusWidget(widgetId);
      e.stopPropagation();
    },
    [store],
  );

  const handleMouseOut = useCallback(
    (e: any) => {
      const state = store.getState();
      const { widgetId, focusDisabled, unfocusWidget } = stableRefs.current;
      const focusedWidget = state.legacy.ui.widgetDragResize.focusedWidgetId;
      const selfNode = wrapperRef.current;

      const isInternalMove =
        selfNode?.contains(e.relatedTarget) && selfNode?.contains(e.target);

      !focusDisabled &&
        unfocusWidget &&
        focusedWidget === widgetId &&
        !isInternalMove &&
        !e.relatedTarget?.classList?.contains?.("t--resize-handle") &&
        e.relatedTarget?.dataset?.maintainWidgetFocus !== "true" &&
        unfocusWidget();
      e.stopPropagation();
    },
    [store],
  );

  return {
    wrapperRef,
    handleClick,
    handleDoubleClick,
    handleContextMenuClick,
    handleMouseOver,
    handleMouseOut,
  };
};
