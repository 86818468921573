import { Label } from "@blueprintjs/core";
import { Dimension } from "@superblocksteam/shared";
import { Dashboard as DashboardComponent } from "@uppy/react";
import { debounce, isEmpty } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { INLINE_ERROR_MESSAGE_HEIGHT } from "legacy/components/editorComponents/ErrorInlineMessage";
import WidgetErrorsWrapper from "legacy/components/editorComponents/WidgetErrorsWrapper";
import {
  ErrorMessagePlacement,
  GridDefaults,
  WIDGET_PADDING,
  WidgetLabelPosition,
} from "legacy/constants/WidgetConstants";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { useAppSelector } from "store/helpers";
import { colors } from "styles/colors";
import createLoggedObserver from "utils/createLoggedObserver";
import logger from "utils/logger";
import { getComponentDimensions } from "utils/size";
import {
  getLabelWidthCssValue,
  labelStyleRaw,
} from "../Shared/widgetLabelStyles";
import {
  getApplicableMaxHeight,
  getApplicableMinHeight,
  isFitContent,
} from "../base/sizing";
import { useStyleClassNames, useTypographyStyling } from "../typographyHooks";
import { getLineHeightInPxFromTextStyle } from "../typographyUtils";
import { DEFAULT_FILE_PICKER_WIDGET_LABEL_STYLE_VARIANT } from "./constants";
import { FilePickerComponentProps } from "./types";

const Wrapper = styled.div`
  flex: 1;
  max-width: 100%;

  [data-uppy-theme="dark"] .uppy-Dashboard-inner {
    background-color: ${colors.GREY_800};
  }

  .uppy-Dashboard-inner {
    border: 1px solid transparent;
  }
  &[data-is-invalid="true"] .uppy-Dashboard-inner {
    border: 1px solid
      ${(props: { $errorBorderColor: string }) => props.$errorBorderColor};
  }
`;

const FilePickerWrapper = styled.div`
  display: flex;

  &[data-vertical="true"] {
    flex-direction: column;
    label {
      flex: 0 0 auto;
      max-width: 100%;
      ${labelStyleRaw.vertical}
    }
  }

  &[data-vertical="false"] {
    label {
      flex: 0 0 var(--label-width);
      text-align: left;
      align-self: flex-start;
      ${labelStyleRaw.horizontal}
    }
  }
`;

const EMPTY_BODY_HEIGHT = 61;
const HEADER_HEIGHT = 50;
const PADDING_EXTRA = 20;

export const FilepickerComponent = (props: FilePickerComponentProps) => {
  const { allowedFileTypes } = props;
  const note: string | undefined = useMemo(() => {
    let str = "";
    if (
      allowedFileTypes &&
      Array.isArray(allowedFileTypes) &&
      !allowedFileTypes.includes("*") &&
      !isEmpty(allowedFileTypes)
    ) {
      str = `Allowed types: ${allowedFileTypes.join(", ")}`;
    } else if (allowedFileTypes && !Array.isArray(allowedFileTypes)) {
      logger.warn(
        `allowedFileTypes expected to be an an array. Type: ${typeof allowedFileTypes} value: ${JSON.stringify(
          allowedFileTypes,
          null,
          2,
        )}`,
      );
    }

    if (str) return str;

    return;
  }, [allowedFileTypes]);
  const theme = useAppSelector(selectGeneratedTheme);

  const maxHeight = getApplicableMaxHeight(props);
  const minHeight = getApplicableMinHeight(props);
  const isAutoHeight = isFitContent(props.height.mode);
  const { componentHeight } = getComponentDimensions(props);
  const isVertical =
    props.labelProps?.text && props.labelProps?.position
      ? props.labelProps?.position === WidgetLabelPosition.TOP
      : true;

  const minHeightPx = minHeight
    ? Dimension.toPx(minHeight, GridDefaults.DEFAULT_GRID_ROW_HEIGHT).value
    : undefined;
  const maxHeightPx = maxHeight
    ? Dimension.toPx(maxHeight, GridDefaults.DEFAULT_GRID_ROW_HEIGHT).value
    : undefined;
  const [filepickerHeight, setFilepickerHeight] = useState<number | undefined>(
    undefined,
  );

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const labelProps = useTypographyStyling({
    textStyle: props.labelProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_FILE_PICKER_WIDGET_LABEL_STYLE_VARIANT,
    applyClassNameStylesToStyle: true,
  });

  const labelClass = useStyleClassNames({
    textStyleVariant: labelProps.textStyleVariant, // Has fallback in place
    isLoading: props.isLoading,
    isDisabled: props.isDisabled,
    type: "label",
  });

  const labelLineHeightPx = getLineHeightInPxFromTextStyle({
    textStyleVariant: labelProps.textStyleVariant,
    nestedProps: props.labelProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_FILE_PICKER_WIDGET_LABEL_STYLE_VARIANT,
    typographies: theme.typographies,
  });

  const getFilepickerFilesSectionDiv = useCallback(() => {
    const header = wrapperRef.current?.querySelector(
      ".uppy-DashboardContent-bar",
    ) as HTMLDivElement | undefined;

    const body = wrapperRef.current?.querySelector(".uppy-Dashboard-files")
      ?.firstChild?.firstChild as HTMLDivElement | undefined;
    return body && header
      ? body.offsetHeight + header.offsetHeight + PADDING_EXTRA
      : undefined;
  }, []);

  const calculateFilepickerHeight = useCallback(() => {
    if (!isAutoHeight) {
      const availableHeight =
        props.errorMessagePlacement === ErrorMessagePlacement.INLINE
          ? componentHeight - INLINE_ERROR_MESSAGE_HEIGHT
          : componentHeight;

      setFilepickerHeight(
        isVertical && props.labelProps?.text
          ? availableHeight - labelLineHeightPx
          : availableHeight,
      );
      return;
    }

    const divHeight = getFilepickerFilesSectionDiv();

    let height = divHeight
      ? divHeight
      : EMPTY_BODY_HEIGHT + HEADER_HEIGHT + PADDING_EXTRA;
    if (maxHeightPx != null && height > maxHeightPx) {
      height = maxHeightPx - 2 * WIDGET_PADDING;
    }
    if (minHeightPx != null && height < minHeightPx) {
      height = minHeightPx - 2 * WIDGET_PADDING;
    }

    setFilepickerHeight(height);
  }, [
    getFilepickerFilesSectionDiv,
    isAutoHeight,
    maxHeightPx,
    minHeightPx,
    componentHeight,
    labelLineHeightPx,
    isVertical,
    props.labelProps?.text,
    props.errorMessagePlacement,
  ]);

  // add a resize observer to the filepicker to update the height when the content changes
  useEffect(() => {
    // Run on mount and adjust on nav resize
    calculateFilepickerHeight();
    const debouncedHeightCalculations = debounce(calculateFilepickerHeight, 1);
    const resizeObserver = createLoggedObserver("FilepickerComponent", () => {
      debouncedHeightCalculations();
    });
    const mutationObserver = new MutationObserver(() => {
      debouncedHeightCalculations();
    });

    const wrapper = wrapperRef.current;
    if (wrapper) {
      mutationObserver.observe(wrapper, {
        childList: true,
        subtree: true,
      });
      resizeObserver.observe(wrapper);
    }

    return () => {
      mutationObserver.disconnect();
      resizeObserver.disconnect();
    };
  }, [calculateFilepickerHeight, props.widgetId, getFilepickerFilesSectionDiv]);

  const styleVars: Record<string, unknown> = {
    "--label-width": getLabelWidthCssValue(props.labelProps?.width),
  };

  return (
    <WidgetErrorsWrapper
      widgetId={props.widgetId}
      showError={!!props.showError}
      messages={props.errorMessages}
      errorMessagePlacement={props.errorMessagePlacement}
      inlineErrorProps={{
        isFitContentWidth: true,
        isFitContentHeight: props.height?.mode === "fitContent",
      }}
      attachTo={wrapperRef}
    >
      {(inlineError) => (
        <FilePickerWrapper data-vertical={isVertical} style={styleVars}>
          {props.labelProps?.text ? (
            <Label className={labelClass} style={labelProps.style}>
              {props.isRequired &&
                props.labelProps?.text?.indexOf("*") === -1 && (
                  <span className={`asterisk ${CLASS_NAMES.ERROR_MODIFIER}`}>
                    *{" "}
                  </span>
                )}
              {props.labelProps?.text}
            </Label>
          ) : null}
          <Wrapper
            ref={wrapperRef}
            id={`filepicker-component-${props.widgetId}`}
            data-is-invalid={!props.isValid && props.showError}
            $errorBorderColor={props.generatedTheme.colors.danger}
          >
            <DashboardComponent
              uppy={props.uppy}
              // This component doesn't re-render correctly, so we are forcing it to re-render when
              // we need it to. If we add more properties, they need to be part of the key.
              key={`${props.widgetId}-${filepickerHeight}-${props.selectionType}-${note}-${props.generatedTheme.mode}-${props.isDisabled}`}
              width={"100%"}
              height={filepickerHeight ?? "auto"}
              proudlyDisplayPoweredByUppy={false}
              hideUploadButton={true}
              note={note}
              style={{ minHeight: minHeightPx, maxHeight: maxHeightPx }}
              theme={props.generatedTheme.mode === "DARK" ? "dark" : "light"}
              disabled={props.isDisabled}
            />
            {inlineError}
          </Wrapper>
        </FilePickerWrapper>
      )}
    </WidgetErrorsWrapper>
  );
};
