import { InputNumber } from "antd";
import { debounce } from "lodash";
import {
  DropdownOption,
  RecommendedSingleDropdown,
} from "components/ui/RecommendedSingleDropdown";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import BaseControl, { ControlProps } from "./BaseControl";
import { StyledDropDownContainer } from "./StyledControls";

const inputStyle = styleAsClass`
  width: 100% !important;
  
  .ant-input-number-group-addon {
    font-size: 12px;
  }

  &.ant-input-number input {
    font-size: 12px;
  }
`;

const DEDFAULT_UNIT_DROPDOWN_WIDTH = 80;
const containerClass = styleAsClass`
  display: flex;
  width: 100%;


  /* The dropdown */
  > *:last-child {
    min-width: ${String(DEDFAULT_UNIT_DROPDOWN_WIDTH)}px;
  }

  .bp5-input {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .ant-input-number-input {
    padding-left: 8px;
  }

  .ant-input-number:focus, .ant-input-number-focused, .ant-input-number:hover {
    border-color: ${
      colors.GREY_100
    }; /* same as default, this just negates the focus styling */
    box-shadow: none;
  }

  .ant-input-number:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 0;
  }

  .ant-input-number:only-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right-width: 1px;
    width: 100%;
  }
    &[data-warning="true"] {
      .ant-input-number {
        border-color: ${colors.ORANGE_600};
      }
      .bp5-input {
        border-color: ${colors.ORANGE_600};
        border-left-color: ${colors.GREY_100};
      }
    }
`;

const warningClass = styleAsClass`
  color: ${colors.ORANGE_600};
  font-size: 12px;
`;

const getNumberFromValue = (value: string | undefined) => {
  return value === undefined ? value : parseFloat(value ?? "0");
};

const getValueWithUnit = (
  value: number | undefined,
  unitValue: string | undefined,
  numberless: boolean,
) => {
  if (numberless || Number.isNaN(value)) {
    return unitValue;
  }
  return `${value ?? ""}${unitValue ?? ""}`;
};

const getUnitFromValue = (value: number | string | undefined) => {
  if (typeof value !== "string") {
    return "";
  }
  const match = value.match(/.*?([a-zA-Z]+)$/);
  if (!match) {
    return "";
  }
  const unitGroup = match[1];
  return unitGroup;
};

class InputNumberControl extends BaseControl<
  InputNumberControlProps,
  {
    inputNumberValue: number | null;
    selectedUnit: string | undefined;
  }
> {
  constructor(props: InputNumberControlProps) {
    super(props);
    if (!this.props.propertyValue) {
      this.state = {
        inputNumberValue: null,
        selectedUnit: this.props.defaultUnit,
      };
      return;
    }
    let unit: string | undefined = getUnitFromValue(this.props.propertyValue);
    unit = this.props.unitOptions?.find((option) => option.value === unit)
      ? unit
      : undefined;
    const number = getNumberFromValue(this.props.propertyValue);
    this.state = {
      inputNumberValue: number ?? null,
      selectedUnit: unit,
    };
  }

  updatePropertyDebounced = debounce(this.updateProperty, 500);

  onValueChange = (value: number | null) => {
    this.setState({ inputNumberValue: value });
    const selectedOption = this.props.unitOptions?.find(
      (option) => option.value === this.state.selectedUnit,
    );
    if (this.state.selectedUnit == null) {
      return;
    }
    this.updatePropertyDebounced(
      this.props.propertyName,
      getValueWithUnit(
        value ?? this.props.minValue ?? 0,
        this.state.selectedUnit,
        Boolean(selectedOption?.numberless),
      ),
    );
  };

  onUnitSelect = (option: DropdownOption) => {
    const oldUnit = this.state.selectedUnit;
    let inputNumberValue = this.state.inputNumberValue;
    if (this.props.transformValueOnUnitChange) {
      inputNumberValue =
        this.props.transformValueOnUnitChange({
          oldUnit,
          newUnit: option.value,
          value: this.state.inputNumberValue,
          widgetProperties: this.props.widgetProperties,
          path: this.props.propertyName,
        }) ?? null;
    }

    this.setState({
      selectedUnit: option.value,
      inputNumberValue,
    });

    const selectedOption = this.props.unitOptions?.find(
      (opt) => opt.value === option.value,
    );
    if (
      this.state.inputNumberValue === undefined &&
      !selectedOption?.numberless
    ) {
      return;
    }

    this.updatePropertyDebounced(
      this.props.propertyName,
      getValueWithUnit(
        inputNumberValue ?? this.props.minValue ?? 0,
        option.value,
        Boolean(selectedOption?.numberless),
      ),
    );
  };

  componentWillUnmount() {
    this.updatePropertyDebounced.flush();
  }

  onBlur = () => {
    this.updatePropertyDebounced.flush();
    this.props.onBlur?.();
  };

  static getControlType() {
    return "INPUT_NUMBER";
  }

  private toDropdownOption(option: any): DropdownOption {
    const key = option.value;
    return {
      ...option,
      key,
      dataTest: `dropdown-option-${option.value}`,
      displayName: option.label,
    } satisfies DropdownOption;
  }

  render() {
    const selectedOption = this.props.unitOptions?.find(
      (option) => option.value === this.state.selectedUnit,
    );
    const isNumberless = Boolean(selectedOption?.numberless);
    const showDropdown =
      this.props.unitOptions?.length &&
      (this.props.unitOptions?.length > 1 || !selectedOption);
    const showNumInput = !isNumberless && selectedOption;

    return (
      <div>
        <div
          className={containerClass}
          data-warning={this.props.warning != null}
        >
          {showNumInput && (
            <InputNumber
              value={this.state.inputNumberValue}
              precision={this.props.precision}
              onChange={this.onValueChange}
              onBlur={this.onBlur}
              onPressEnter={this.onBlur}
              className={inputStyle}
              addonAfter={showDropdown ? undefined : this.state.selectedUnit}
              controls={false}
              data-test={`${this.props.dataTest}-input`}
              min={this.props.minValue}
              max={this.props.maxValue}
              disabled={this.props.isDisabled}
            />
          )}
          {showDropdown && (
            <StyledDropDownContainer
              style={{
                width: !showNumInput
                  ? "100%"
                  : (this.props.unitDropdownWidth ??
                    DEDFAULT_UNIT_DROPDOWN_WIDTH),
              }}
            >
              <RecommendedSingleDropdown
                data-test={`${this.props.dataTest}-dropdown`}
                placeholder={this.props.propertyValue} // this to show the existing value if it doesn't match an option
                options={
                  this.props.unitOptions?.map(this.toDropdownOption) ?? []
                }
                onChange={this.onUnitSelect}
                value={this.state.selectedUnit}
                disabled={
                  this.props.isDisabled ||
                  (this.props.unitOptions?.length === 1 &&
                    this.state.selectedUnit === this.props.unitOptions[0].value)
                }
                tooltipPlacement="left"
                popoverProps={{
                  matchTargetWidth: !showNumInput,
                }}
                popoverContentProps={
                  showNumInput
                    ? {
                        style: {
                          width: "170px",
                        },
                      }
                    : {}
                }
              />
            </StyledDropDownContainer>
          )}
        </div>
        {this.props.warning ? (
          <div className={warningClass}>{this.props.warning}</div>
        ) : null}
      </div>
    );
  }
}

export type UnitOption = {
  label: string;
  value: string;
  numberless?: boolean;
} & Partial<DropdownOption>;

export interface InputNumberControlProps extends ControlProps {
  defaultUnit?: string;
  precision?: number;
  isDisabled?: boolean;
  defaultValue?: any;
  unitDropdownWidth?: number;
  warning?: string;
  minValue?: number;
  maxValue?: number;
  transformValueOnUnitChange?: (params: {
    oldUnit: string | undefined;
    newUnit: string;
    value: unknown;
    widgetProperties?: any;
    path: string;
  }) => number | undefined;
}

export default InputNumberControl;
