import styled from "styled-components";
import { LoadingAnimator } from "legacy/widgets/shared";
import { colors } from "styles/colors";

const LoadingLine = styled.div`
  height: 32px;
  width: 100%;
  border-radius: 4px;
  background: ${colors.GREY_50};
`;

const PropertyControlLoading = () => {
  return (
    <div style={{ position: "relative" }}>
      <LoadingLine />
      <LoadingAnimator $width={150} bgColor={colors.WHITE} />
    </div>
  );
};

export default PropertyControlLoading;
