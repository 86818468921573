import { Instance, Options } from "@popperjs/core";
import { Dimension } from "@superblocksteam/shared";
import { useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Popper from "components/ui/Popper";
import { Layers } from "legacy/constants/Layers";
import { GridDefaults } from "legacy/constants/WidgetConstants";
import { useIsPopperOverflow } from "legacy/hooks/useIsPopperOverflow";
import { useMountingNode } from "legacy/hooks/useMountingNode";
import { getResponsiveCanvasWidth } from "legacy/selectors/applicationSelectors";
import { AppState } from "store/types";
import { NAME_BELOW_GAP } from "./WidgetNameComponent";

const OutlineWrapper = styled.div`
  display: inline-flex;
  height: 20px;
  padding: 2px 4px;
  justify-content: flex-end;
  align-items: center;
  border-radius: 3px;
  background: ${({ theme }) => theme.colors.ACCENT_BLUE_NEW_DARKER};
  text-align: center;
  font-family: var(--font-family);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;

  span:first-child,
  span:last-child {
    color: ${({ theme }) => theme.colors.WHITE};
  }
  span:nth-child(2) {
    color: #ffffffcc;
    margin: 0 3px;
    padding-bottom: 1px;
  }
`;

const getText = (dim: Dimension, isWidth: boolean) => {
  switch (dim.mode) {
    case "fitContent":
      return "Fit";
    case "fillParent":
      return "Fill";
    case "gridUnit": {
      if (isWidth) {
        return "Fluid";
      } else {
        return `${Math.round(
          dim.value * GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
        )} px`;
      }
    }
    case "px":
      return `${Math.round(dim.value)} px`;
    default:
      return `${dim.value}${dim.mode}`;
  }
};
const popperModifiers: Options["modifiers"] = [
  {
    name: "offset",
    options: {
      offset: [0, NAME_BELOW_GAP],
    },
  },
];

type Props = {
  width: Dimension;
  height: Dimension;
  targetNode?: HTMLElement;
  widgetId: string;
};

export const SizeOverlay = (props: Props) => {
  const widthText = getText(props.width, true);
  const heightText = getText(props.height, false);
  // Get the width of the page, as it's changes require the popper to reposition
  const pageWidth = useSelector(getResponsiveCanvasWidth);
  const isResizing = useSelector(
    (state: AppState) => state.legacy.ui.widgetDragResize.isResizing,
  );

  const mountingNode = useMountingNode(props.widgetId);

  const popperInstanceRef = useRef<Instance | null>(null);

  const onCreate = useCallback((instance: Instance) => {
    popperInstanceRef.current = instance;
  }, []);

  const isPopperOverflow = useIsPopperOverflow({
    widgetId: props.widgetId,
    popperInstanceRef,
    targetNode: props.targetNode ?? null,
  });

  if (!isResizing) return null;
  return (
    <Popper
      zIndex={Layers.widgetName}
      onCreate={onCreate}
      isOpen={true}
      targetNode={props.targetNode ?? undefined}
      modifiers={popperModifiers}
      placement={"bottom"}
      mountingNode={mountingNode ?? document.body}
      dependencies={[props.width, props.height, pageWidth]}
    >
      <OutlineWrapper
        style={{ visibility: isPopperOverflow ? "hidden" : "visible" }}
      >
        <span>{widthText}</span>
        <span>x</span>
        <span>{heightText}</span>
      </OutlineWrapper>
    </Popper>
  );
};
