import { Dimension, Margin, Padding } from "@superblocksteam/shared";
import { omit } from "lodash";
import {
  useContext,
  useRef,
  memo,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { useSelector } from "react-redux";
import { useIsKeyDownState } from "hooks/ui/useIsKeyDown";
import { WidgetResize } from "legacy/actions/pageActions";
import { EditorContext } from "legacy/components/editorComponents/EditorContextProvider";
import {
  CanvasAlignment,
  CanvasDistribution,
  CanvasLayout,
  GridDefaults,
  WIDGET_PADDING,
} from "legacy/constants/WidgetConstants";
import {
  useWidgetSelection,
  useWidgetDragResize,
} from "legacy/hooks/dragResizeHooks";
import { getParentToOpenIfAny } from "legacy/hooks/useClickOpenPropPane";
import { APP_MODE } from "legacy/reducers/types";
import { getAppMode } from "legacy/selectors/applicationSelectors";
import {
  selectIsDragging,
  selectIsResizing,
} from "legacy/selectors/dndSelectors";
import { getFlattenedCanvasWidgets } from "legacy/selectors/editorSelectors";
import {
  getSingleSelectedWidgetId,
  getIsMultipleWidgetsSelected,
} from "legacy/selectors/sagaSelectors";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import AnalyticsUtil from "legacy/utils/AnalyticsUtil";
import VisibilityContainer from "legacy/widgets/base/VisibilityContainer";
import {
  getComponentDimensions,
  hasRuntimePositionProperties,
} from "utils/size";
import WidgetFactory from "../../Factory";
import { WidgetOperations } from "../../WidgetOperations";
import { FlattenedWidgetLayoutProps } from "../../shared";
import { DropTargetContext } from "../DropTargetUtils";
import Resizable from "../Resizable";
import { UIElementSize } from "../ResizableUtils";
import {
  TopHandleWithRectangleStyles,
  BottomHandleWithRectangleStyles,
  LeftHandleWithRectangleStyles,
  RightHandleWithRectangleStyles,
} from "../ResizeStyledComponents";
import { isDynamicSize, isFillParent, isFixedHeight } from "../sizing";
import { getCanvasMinWidth } from "../sizing/canvasWidthUtil";
import {
  FillParentHorizontalResizeDisabledTooltip,
  FillParentVerticalResizeDisabledTooltip,
  FitContentVerticalResizeDisabledTooltip,
  FitContentHorizontalResizeDisabledTooltip,
  StretchHorizontalResizeDisabledTooltip,
  StretchVerticalResizeDisabledTooltip,
  allHandles,
  makeHandle,
  getNewDimensions,
} from "./common";
import { useClearSectionSizingContext } from "./useClearSectionSizingContext";
import { useStickyDrag } from "./useStickyDrag";
import type { WidgetProps, WidgetPropsRuntime } from "../../BaseWidget";
import type { XYCoord } from "react-dnd";

type ResizableStackedComponentProps = WidgetPropsRuntime & {
  ignoreCollision?: boolean;
  hasInvalidProps: boolean;
};

const KEY_DOWN_KEYS = ["Shift"];

const getHeightResizeDisabledText = (
  dynamicHeight: boolean,
  fillParentHeight: boolean,
  isStretchHeight: boolean,
): string | undefined => {
  if (isStretchHeight) {
    return StretchVerticalResizeDisabledTooltip;
  } else if (fillParentHeight) {
    return FillParentVerticalResizeDisabledTooltip;
  } else if (dynamicHeight) {
    return FitContentVerticalResizeDisabledTooltip;
  }
};

const getWidthResizeDisabledText = (
  dynamicWidth: boolean,
  fillParentWidth: boolean,
  isStretchWidth: boolean,
): string | undefined => {
  if (isStretchWidth) {
    return StretchHorizontalResizeDisabledTooltip;
  } else if (fillParentWidth) {
    return FillParentHorizontalResizeDisabledTooltip;
  } else if (dynamicWidth) {
    return FitContentHorizontalResizeDisabledTooltip;
  }
};

const ResizableStackedComponent = memo(
  (props: ResizableStackedComponentProps) => {
    const {
      widgetId,
      widgetName,
      type,
      left: { value: left },
      top: { value: top },
      parentRowSpace,
      parentColumnSpace,
      parentId,
    } = props;

    const resizableRef = useRef<HTMLDivElement>(null);
    const { updateWidget } = useContext(EditorContext);
    const shiftKeyIsDown = useIsKeyDownState(KEY_DOWN_KEYS);
    const metaKeyIsDown = useIsKeyDownState(["Meta"]);

    const {
      updateDropTargetRows,
      persistDropTargetRows,
      updateTemporaryResizeValue,
    } = useContext(DropTargetContext);

    const { selectWidgets, focusWidget } = useWidgetSelection();
    const {
      setIsResizing,
      setWidgetResizingDimensions,
      setResizingStackWidth,
    } = useWidgetDragResize();
    const selectedWidget = useSelector(getSingleSelectedWidgetId);
    const multipleWidgetsSelected = useSelector(getIsMultipleWidgetsSelected);

    const isDragging = useSelector(selectIsDragging);
    const isResizing = useSelector(selectIsResizing);
    const canvasWidgets = useSelector(getFlattenedCanvasWidgets);
    const parentWidgetToSelect = getParentToOpenIfAny(widgetId, canvasWidgets);

    const isWidgetSelected = selectedWidget === widgetId;
    const isParentSelected = Boolean(
      parentWidgetToSelect && selectedWidget === parentWidgetToSelect.widgetId,
    );

    const parentWidget = canvasWidgets[parentId] as FlattenedWidgetLayoutProps &
      WidgetProps; // todo fix getFlattenedCanvasWidgets's type
    const parentPadding = parentWidget?.padding;
    const grandParentWidget = canvasWidgets[parentWidget?.parentId];
    const grandParentWidthMode = grandParentWidget?.width?.mode;

    // Calculate the dimensions of the widget,
    // The ResizableContainer's size prop is controlled
    const getTotalPadding = (
      padding: ResizableStackedComponentProps["padding"],
    ): { horizontal: number; vertical: number } => {
      return {
        vertical: Padding.y(padding).value,
        horizontal: Padding.x(padding).value,
      };
    };
    const totalPadding = useMemo(
      () => getTotalPadding(parentPadding),
      [parentPadding],
    );
    const theme = useSelector(selectGeneratedTheme);
    const appMode = useSelector(getAppMode) ?? APP_MODE.PUBLISHED;
    const maxBottomPx =
      WidgetFactory.getWidgetMinimumHeight(
        parentWidget,
        canvasWidgets,
        theme,
        appMode,
        {},
      ) ?? Dimension.px(0);
    const canvasWidth = useMemo(() => {
      if (!parentWidget || !parentWidget.children) {
        return 0;
      }
      const canvasMinWidth = getCanvasMinWidth(
        parentWidget,
        parentWidget.children.map((id) => canvasWidgets[id]) as any,
      );
      return canvasMinWidth.value;
    }, [parentWidget, canvasWidgets]);

    const { componentHeight, componentWidth } = useMemo(() => {
      return getComponentDimensions(props);
    }, [props]);

    const dimensions: UIElementSize = useMemo(
      () => ({
        width: componentWidth - WIDGET_PADDING * 2,
        height: componentHeight - WIDGET_PADDING * 2,
      }),
      [componentWidth, componentHeight],
    );

    const boundingRect = useMemo(() => {
      if (
        !parentWidget ||
        !parentWidget.gridColumns ||
        !parentWidget.internalWidth
      )
        return {};
      if (!hasRuntimePositionProperties(parentWidget)) return {};
      return {
        width: Dimension.toPx(
          parentWidget.internalWidth,
          parentWidget.parentColumnSpace,
        ).value,
        height:
          Dimension.toPx(parentWidget.height, parentWidget.parentRowSpace)
            .value - totalPadding.vertical,
      };
    }, [parentWidget, totalPadding.vertical]);

    const isSpaceAvailable = useCallback(
      (newDimensions: UIElementSize, position: XYCoord) => {
        if (
          boundingRect?.width == null ||
          boundingRect?.height == null ||
          newDimensions.width < 1 ||
          newDimensions.height < 1
        ) {
          return false;
        }

        const marginYPx = Margin.y(props.margin) ?? Dimension.px(0);
        const marginXPx = Margin.x(props.margin) ?? Dimension.px(0);

        const isVStack = parentWidget.layout === CanvasLayout.VSTACK;
        let newBottomPx: Dimension<"px">;
        let resizingStackWidth;
        if (isVStack) {
          const heightDiff = Dimension.px(
            newDimensions.height - dimensions.height,
          );
          newBottomPx = Dimension.add(maxBottomPx, heightDiff).asFirst();
          resizingStackWidth = Math.max(canvasWidth, newDimensions.width);
        } else {
          newBottomPx = Dimension.max(
            maxBottomPx,
            Dimension.add(
              Dimension.px(newDimensions.height),
              marginYPx,
            ).asFirst(),
          ).asFirst();
          const widthDiff = newDimensions.width - dimensions.width;
          resizingStackWidth = Math.max(widthDiff + canvasWidth, canvasWidth);
        }

        updateDropTargetRows?.(
          widgetId,
          Dimension.toGridUnit(
            newBottomPx,
            GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
          ).raw().value,
        );

        const canExtendHorizontally =
          parentWidget.layout === CanvasLayout.HSTACK ||
          grandParentWidthMode === "fitContent";
        // check if newDimensions are within the parent bounding rect
        // width and height
        if (
          !canExtendHorizontally &&
          newDimensions.width + marginXPx.value > boundingRect.width
        ) {
          return false;
        }
        if (newBottomPx.value > boundingRect.height) {
          // We need to grow the canvas height

          if (!persistDropTargetRows) return false;
          persistDropTargetRows(
            widgetId,
            Dimension.toGridUnit(
              newBottomPx,
              GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
            ).raw().value,
          );
        }
        setResizingStackWidth && setResizingStackWidth(resizingStackWidth);
        updateTemporaryResizeValue &&
          updateTemporaryResizeValue(widgetId, newDimensions, position);

        return true;
      },
      [
        boundingRect.width,
        boundingRect.height,
        parentWidget.layout,
        props.margin,
        updateDropTargetRows,
        widgetId,
        grandParentWidthMode,
        setResizingStackWidth,
        updateTemporaryResizeValue,
        dimensions.height,
        dimensions.width,
        maxBottomPx,
        canvasWidth,
        persistDropTargetRows,
      ],
    );

    const clearColumnCanvasExtensionRows = useClearSectionSizingContext();

    // onResizeStop handler
    // when done resizing, check if;
    // 1) There is no collision
    // 2) There is a change in widget size
    // Update widget, if both of the above are true.
    const updateSize = useCallback(
      (newDimensions: UIElementSize, position: XYCoord) => {
        const { newWidth, newHeight } = getNewDimensions({
          currentWidth: props.width,
          currentHeight: props.height,
          newDimensions,
          parentColumnSpace,
          parentRowSpace,
        });

        const payload: Omit<WidgetResize, "widgetId"> = {
          position: {
            // Use existing values for now
            left: Dimension.gridUnit(left),
            top: Dimension.gridUnit(top),
          },
          size: {
            width: newWidth,
            height: newHeight,
          },
        };

        updateWidget &&
          updateWidget(WidgetOperations.WIDGET_RESIZE, widgetId, payload);

        // Tell the Canvas that we've stopped resizing
        // we need to pass as a callback to setIsResizing to be called at the right time
        // otherwise this change is reflected in the next render
        // before the redux values are reflected, which causes a temporary flicker
        // back to the original size
        setIsResizing?.(false, () => {
          setResizingStackWidth?.(undefined);
          updateTemporaryResizeValue?.(); // clear the value
        });

        if (props.openParentPropertyPane && parentWidgetToSelect) {
          // Only select the parent if we are resizing a hidden containers like the
          // first cell in a grid, since these don't have any properties
          selectWidgets &&
            !isParentSelected &&
            selectWidgets([parentWidgetToSelect.widgetId]);
          focusWidget(parentWidgetToSelect.widgetId);
        } else {
          // Keep the focus on the current resizable otherwise
          selectWidgets && !isWidgetSelected && selectWidgets([widgetId]);
        }

        clearColumnCanvasExtensionRows();

        AnalyticsUtil.logEvent("WIDGET_RESIZE_END", {
          widgetName: widgetName,
          widgetType: type,
          startHeight: dimensions.height,
          startWidth: dimensions.width,
          endHeight: newDimensions.height,
          endWidth: newDimensions.width,
        });
      },
      [
        props.width,
        props.height,
        props.openParentPropertyPane,
        parentColumnSpace,
        parentRowSpace,
        left,
        top,
        updateWidget,
        widgetId,
        parentWidgetToSelect,
        clearColumnCanvasExtensionRows,
        widgetName,
        type,
        dimensions.height,
        dimensions.width,
        setIsResizing,
        updateTemporaryResizeValue,
        selectWidgets,
        isParentSelected,
        focusWidget,
        isWidgetSelected,
        setResizingStackWidth,
      ],
    );

    const handleResizeStart = useCallback(() => {
      setIsResizing && !isResizing && setIsResizing(true);
      selectWidgets && selectedWidget !== widgetId && selectWidgets([widgetId]);
      AnalyticsUtil.logEvent("WIDGET_RESIZE_START", {
        widgetName: widgetName,
        widgetType: type,
      });
    }, [
      isResizing,
      type,
      widgetId,
      widgetName,
      selectWidgets,
      selectedWidget,
      setIsResizing,
    ]);

    const _disabledResizeHandles = (props as any).disabledResizeHandles;
    const disabledResizeHandles = useMemo(() => {
      return JSON.stringify(_disabledResizeHandles ?? []);
    }, [_disabledResizeHandles]);

    const dynamicHeight = isDynamicSize(props.height.mode);
    const dynamicWidth = isDynamicSize(props.width.mode);

    const fillParentHeight = isFillParent(props.height.mode);
    const fillParentWidth = isFillParent(props.width.mode);

    const handles = useMemo(() => {
      let handles: Partial<typeof allHandles> = { ...allHandles };

      const isVStack = parentWidget.layout === CanvasLayout.VSTACK;
      const isHStack = parentWidget.layout === CanvasLayout.HSTACK;

      const isStretchAlignment =
        (parentWidget.alignment || CanvasAlignment.STRETCH) ===
        CanvasAlignment.STRETCH;

      const isStretchDistribution =
        (parentWidget.distribution || CanvasDistribution.STRETCH) ===
        CanvasDistribution.STRETCH;

      const isStretchHeight = isHStack && isStretchDistribution;
      const isStretchWidth = isVStack && isStretchAlignment;

      const isAutoHeight = dynamicHeight || isStretchHeight;
      const isAutoWidth = dynamicWidth || isStretchWidth;

      const heightDisabledText = getHeightResizeDisabledText(
        dynamicHeight,
        fillParentHeight,
        isStretchHeight,
      );

      const widthDisabledText = getWidthResizeDisabledText(
        dynamicWidth,
        fillParentWidth,
        isStretchWidth,
      );

      if (isAutoHeight || isAutoWidth) {
        handles = {
          top: makeHandle(TopHandleWithRectangleStyles, heightDisabledText),
          bottom: makeHandle(
            BottomHandleWithRectangleStyles as any,
            heightDisabledText,
          ),
          left: makeHandle(LeftHandleWithRectangleStyles, widthDisabledText),
          right: makeHandle(RightHandleWithRectangleStyles, widthDisabledText),
        };
      }

      return omit(handles, JSON.parse(disabledResizeHandles));
    }, [
      disabledResizeHandles,
      parentWidget.layout,
      parentWidget.alignment,
      parentWidget.distribution,
      dynamicHeight,
      dynamicWidth,
      fillParentHeight,
      fillParentWidth,
    ]);

    const snapGrid = useMemo(() => {
      return {
        x:
          props.width.mode === "px"
            ? metaKeyIsDown
              ? 1
              : GridDefaults.DEFAULT_GRID_COLUMN_WIDTH
            : props.parentColumnSpace,
        y:
          metaKeyIsDown && isFixedHeight(props.height.mode)
            ? 1
            : props.parentRowSpace,
      };
    }, [
      props.width.mode,
      props.parentColumnSpace,
      props.height.mode,
      props.parentRowSpace,
      metaKeyIsDown,
    ]);

    useEffect(() => {
      if (selectedWidget === widgetId) {
        resizableRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest",
        });
      }
    }, [selectedWidget, widgetId]);

    const stickyDragTransformer = useStickyDrag({
      widgetId,
      parentId,
    });

    return (
      <Resizable
        ref={resizableRef}
        handles={handles}
        componentHeight={dimensions.height}
        componentWidth={dimensions.width}
        onStart={handleResizeStart}
        onStop={updateSize}
        onResize={setWidgetResizingDimensions}
        snapGrid={snapGrid}
        enable={
          !isDragging &&
          isWidgetSelected &&
          !multipleWidgetsSelected &&
          !props.resizeDisabled &&
          !shiftKeyIsDown
        }
        isSelected={isWidgetSelected}
        isSpaceAvailable={isSpaceAvailable}
        dragSizeTransformer={stickyDragTransformer}
        hasInvalidProps={props.hasInvalidProps}
        widget={props}
      >
        <VisibilityContainer isVisible={!!props.isVisible} widgetId={widgetId}>
          {props.children}
        </VisibilityContainer>
      </Resizable>
    );
  },
);

ResizableStackedComponent.displayName = "ResizableStackedComponent";
export default ResizableStackedComponent;
