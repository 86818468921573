import { Select, SelectProps } from "antd";
import { ReactComponent as ChevronDown } from "assets/icons/common/chevron-down-dropdown.svg";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

const SelectClass = styleAsClass`
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 8px;
  }
`;

const DropdownSelect = (props: SelectProps) => {
  return (
    <Select
      suffixIcon={<ChevronDown color={colors.GREY_300} />}
      {...props}
      className={`${props.className || ""} ${SelectClass} EricsSelectClass`}
      removeIcon={null}
    />
  );
};

export default DropdownSelect;
