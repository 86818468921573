import { CurrencyList } from "legacy/constants/FormatConstants";
import {
  PropsPanelCategory,
  type PropertyPaneCategoryConfig,
} from "legacy/constants/PropertyControlConstants";
import { PanelCategory } from "legacy/pages/Editor/PropertyPane/propertyPaneCategoryUtils";
import {
  DEFAULT_CONTAINER_BORDER_OBJECT,
  EMPTY_RADIUS,
} from "legacy/themes/constants";
import { DEFAULT_HEADER_TEXT_STYLE_VARIANT } from "legacy/themes/typographyConstants";
import { NUMBER_FORMATTING_OPTIONS } from "legacy/utils/FormatUtils";
import { createPerCornerBorderRadius } from "pages/Editors/AppBuilder/Sidebar/BorderRadiusEditor";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import HelpTooltip from "../Shared/HelpTooltip";
import {
  maximumFractionDigitsProperty,
  minimumFractionDigitsProperty,
} from "../appearanceProperties";
import { sizeProperties, visibleProperties } from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { styleProperties, typographyProperties } from "../styleProperties";
import { type ChartWidgetProps } from "./ChartWidget";
import {
  isDefinition,
  isNonXYChart,
  isTemporalXYChart,
  isXYChart,
} from "./utils";

const config: PropertyPaneCategoryConfig<ChartWidgetProps>[] = [
  PanelCategory(PropsPanelCategory.Content, [
    {
      helpText: "Adds a header to the top of the chart",
      propertyName: "headerTitle",
      label: "Header",
      controlType: "INPUT_TEXT",
      placeholderText: "Chart name",
      defaultValue: "Chart name",
      visibility: "SHOW_NAME",
      isRemovable: true,
      inputType: "text",
      isBindProperty: true,
      isTriggerProperty: false,
    },
    {
      helpText: (
        <HelpTooltip
          text="Chart definition."
          docsUrl="https://docs.superblocks.com/components/charts"
        />
      ),
      propertyName: "chartDefinition",
      label: "Definition",
      controlType: "RADIO_BUTTON_GROUP",
      defaultValue: "ui",
      options: [
        { label: "UI", value: "ui" },
        { label: "Plotly", value: "plotly" },
      ],
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
    },
    {
      helpText: "Plotly chart JSON",
      propertyName: "plotlyChartJson",
      label: "Plotly chart JSON",
      placeholderText: '{"data":..., "layout":...}',
      controlType: "INPUT_TEXT",
      inputType: "TEXT",
      isBindProperty: true,
      isTriggerProperty: false,
      hidden: (props: ChartWidgetProps) => !isDefinition(props, "plotly"),
    },
    {
      helpText: "Plotly chart layout JSON",
      propertyName: "plotlyChartLayout",
      label: "Layout JSON",
      forceVertical: true,
      placeholderText: '{ "margin": {...} ... }',
      controlType: "INPUT_TEXT",
      inputType: "TEXT",
      isBindProperty: true,
      isTriggerProperty: false,
      hidden: (props: ChartWidgetProps) => !isDefinition(props, "plotly"),
    },
    {
      helpText: (
        <HelpTooltip
          text="Plotly chart configuration JSON"
          docsUrl="https://plotly.com/javascript/configuration-options/"
        />
      ),
      propertyName: "plotlyChartConfig",
      label: "Configuration JSON",
      forceVertical: true,
      placeholderText: '{ "staticPlot": true, "editable": … }',
      controlType: "INPUT_TEXT",
      inputType: "TEXT",
      isBindProperty: true,
      isTriggerProperty: false,
      hidden: (props: ChartWidgetProps) => !isDefinition(props, "plotly"),
    },
    {
      helpText: "Populates the chart with the data",
      propertyName: "chartData",
      placeholderText: 'Enter [{ "x": "val", "y": "val" }]',
      label: "Data",
      forceVertical: true,
      controlType: "INPUT_TEXT",
      inputType: "ARRAY",
      isBindProperty: true,
      isTriggerProperty: false,
      hidden: (props: ChartWidgetProps) => !isDefinition(props, "ui"),
    },
    {
      helpText: "Changes the visualization of the chart data",
      propertyName: "chartType",
      label: "Visualization",
      controlType: "DROP_DOWN",
      options: [
        { label: "Line chart", value: "LINE_CHART" },
        { label: "Bar chart", value: "BAR_CHART" },
        { label: "Column chart", value: "COLUMN_CHART" },
        { label: "Area chart", value: "AREA_CHART" },
        { label: "Scatter chart", value: "SCATTER_CHART" },
        { label: "Pie chart", value: "PIE_CHART" },
      ],
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
      hidden: (props: ChartWidgetProps) => !isDefinition(props, "ui"),
    },
    {
      propertyName: "x.field",
      label: "X-axis values",
      controlType: "CHART_FIELD_SELECTOR",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
      hidden: (props: ChartWidgetProps) =>
        !isDefinition(props, "ui") || isNonXYChart(props),
    },
    {
      propertyName: "y",
      label: "Y-axis values",
      controlType: "CHART_MULTI_SERIES",
      headerControlType: "ADD_CHART_SERIES",
      isBindProperty: true,
      isTriggerProperty: false,
      hidden: (props: ChartWidgetProps) =>
        !isDefinition(props, "ui") || isNonXYChart(props),
    },
    {
      propertyName: "y.aggregation",
      label: "Y-aggregation",
      controlType: "DROP_DOWN",
      options: [
        { label: "None", value: undefined },
        { label: "Sum", value: "sum" },
        { label: "Count", value: "count" },
      ],
      isBindProperty: true,
      isTriggerProperty: false,
      hidden: (props: ChartWidgetProps) =>
        !isDefinition(props, "ui") || isNonXYChart(props),
      visibility: "SHOW_NAME",
      isRemovable: true,
      defaultValue: "count",
    },
    {
      propertyName: "size.field",
      label: "Size field",
      controlType: "CHART_FIELD_SELECTOR",
      isBindProperty: true,
      isTriggerProperty: false,
      hidden: (props: ChartWidgetProps) =>
        !isDefinition(props, "ui") || isXYChart(props),
    },
    {
      propertyName: "groupBy.field",
      label: "Group by",
      controlType: "CHART_FIELD_SELECTOR",
      isBindProperty: true,
      isTriggerProperty: false,
      hidden: (props: ChartWidgetProps) => !isDefinition(props, "ui"),
      visibility: "SHOW_NAME",
      isRemovable: true,
      defaultValue: "",
    },
  ]),
  PanelCategory(PropsPanelCategory.Appearance, [
    ...typographyProperties({
      defaultVariant: DEFAULT_HEADER_TEXT_STYLE_VARIANT,
      textStyleParentDottedPath: "headerProps",
      propertyNameForHumans: "Header",
      hiddenIfPropertyNameIsNullOrFalse: "headerTitle",
    }),
    ...styleProperties({
      backgroundColorThemeValue: "{{ theme.colors.neutral }}",
      defaultBorderProperty: DEFAULT_CONTAINER_BORDER_OBJECT,
      borderThemeValue: DEFAULT_CONTAINER_BORDER_OBJECT,
      borderRadiusThemeValue: ({ theme }: { theme: any }) => {
        return {
          treatAsNull: false,
          value: createPerCornerBorderRadius(theme.borderRadius),
        };
      },
      defaultBorderRadiusProperty: EMPTY_RADIUS,
    }),
    {
      helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
      propertyName: "animateLoading",
      label: "Loading animation",
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
      propertyCategory: PropsPanelCategory.Appearance,
    },
    {
      propertyName: "x.axisTitle",
      label: "X-axis label",
      controlType: "INPUT_TEXT",
      isJSConvertible: false,
      isBindProperty: true,
      isTriggerProperty: false,
      hidden: (props: ChartWidgetProps) =>
        !isDefinition(props, "ui") || isNonXYChart(props),
    },
    {
      propertyName: "x.dataType",
      label: "X-axis type",
      controlType: "DROP_DOWN",
      hidden: (props: ChartWidgetProps) =>
        !isDefinition(props, "ui") || isNonXYChart(props),
      isBindProperty: false,
      isTriggerProperty: false,
      options: [
        {
          label: "Auto",
          value: "auto",
        },
        {
          label: "Time",
          value: "temporal",
        },
        {
          label: "Categorical",
          value: "nominal",
        },
        {
          label: "Continuous",
          value: "quantitative",
        },
      ],
    },
    {
      propertyName: "x.timeUnit",
      label: "X time granularity",
      controlType: "DROP_DOWN",
      hidden: (props: ChartWidgetProps) =>
        !isDefinition(props, "ui") ||
        isNonXYChart(props) ||
        !isTemporalXYChart(props),
      isBindProperty: false,
      isTriggerProperty: false,
      options: [
        {
          label: "Auto",
          value: undefined,
        },
        {
          label: "Monthly",
          value: "yearmonth",
        },
        {
          label: "Weekly",
          value: "yearweek",
        },
        {
          label: "Daily",
          value: "yearmonthdate",
        },
        {
          label: "Hourly",
          value: "yearmonthdatehours",
        },
        {
          label: "Day of week",
          value: "day",
        },
        {
          label: "Hour of day",
          value: "hours",
        },
        {
          label: "Month of year",
          value: "month",
        },
      ],
    },
    {
      propertyName: "y.axisTitle",
      label: "Y-axis label",
      controlType: "INPUT_TEXT",
      isJSConvertible: false,
      isBindProperty: true,
      isTriggerProperty: false,
      hidden: (props: ChartWidgetProps) =>
        !isDefinition(props, "ui") || isNonXYChart(props),
    },
    {
      propertyName: "y.dataType",
      label: "Y-axis type",
      controlType: "DROP_DOWN",
      hidden: (props: ChartWidgetProps) =>
        !isDefinition(props, "ui") || isNonXYChart(props),
      isBindProperty: false,
      isTriggerProperty: false,
      options: [
        {
          label: "Number",
          value: "number",
        },
        {
          label: "Currency",
          value: "currency",
        },
      ],
    },
    {
      propertyName: "y.currency",
      label: "ISO currency code",
      controlType: "DROP_DOWN",
      hidden: (props: ChartWidgetProps) =>
        !isDefinition(props, "ui") ||
        isNonXYChart(props) ||
        props.y.dataType !== "currency",
      isBindProperty: true,
      isJSConvertible: true,
      isTriggerProperty: false,
      defaultValue: "USD",
      options: CurrencyList.map((item) => ({
        label: item,
        value: item,
      })),
    },
    {
      propertyName: "y.notation",
      label: "Number formatting",
      controlType: "DROP_DOWN",
      hidden: (props: ChartWidgetProps) =>
        !isDefinition(props, "ui") ||
        isNonXYChart(props) ||
        (props.y.dataType !== "number" && props.y.dataType !== "currency"),
      isBindProperty: false,
      isTriggerProperty: false,
      defaultValue: "standard",
      options: NUMBER_FORMATTING_OPTIONS,
    },
    minimumFractionDigitsProperty({
      propertyName: "y.minimumFractionDigits",
      hidden: (props: ChartWidgetProps) =>
        !isDefinition(props, "ui") ||
        isNonXYChart(props) ||
        (props.y.dataType !== "number" && props.y.dataType !== "currency"),
    }),
    maximumFractionDigitsProperty({
      propertyName: "y.maximumFractionDigits",
      hidden: (props: ChartWidgetProps) =>
        !isDefinition(props, "ui") ||
        isNonXYChart(props) ||
        (props.y.dataType !== "number" && props.y.dataType !== "currency"),
    }),
    {
      propertyName: "legend.position",
      label: "Legend position",
      helpText: "The placement of the legend in the chart",
      defaultValue: "right",
      controlType: "DROP_DOWN",
      hidden: (props: ChartWidgetProps) =>
        !isDefinition(props, "ui") ||
        (props.y.series.length <= 1 && !props.groupBy.field),
      options: [
        {
          value: "right",
          label: "Right",
        },
        {
          value: "bottom",
          label: "Bottom",
        },
        {
          value: "left",
          label: "Left",
        },
        {
          value: "top",
          label: "Top",
        },
        {
          value: "none",
          label: "None",
        },
      ],
      isBindProperty: false,
      isTriggerProperty: false,
    },
  ]),
  PanelCategory(PropsPanelCategory.Layout, [
    ...sizeProperties(),
    ...visibleProperties({ useJsExpr: false }),
  ]),
  PanelCategory(PropsPanelCategory.EventHandlers, [
    getPopoverConfig("onSelectData", "", {
      hidden: (props: ChartWidgetProps) => !isDefinition(props, "ui"),
    }),
  ]),
];
export default config;
